import { useQuery } from 'react-query'

import { useScheduleHelper } from 'src/hooks/useScheduleHelper'
import { PaymentScheduleCalendar } from 'src/components/PaymentSchedule/shared/PaymentScheduleCalendar'
import { StepsEnum } from 'src/types'

export function PaymentCalendarComponent() {
  const {
    cachedSchedule,
    frequency,
    monthlyChoice,
    paymentCycleDueDateStr,
    generateAndSaveSchedule,
  } = useScheduleHelper()

  const { isFetching } = useQuery<Promise<void>>(
    ['generatePaymentSchedule'],
    () =>
      generateAndSaveSchedule({
        schedule: frequency,
        monthly_choice: monthlyChoice,
        first_payment_date: paymentCycleDueDateStr!,
      }),
    {
      enabled: false,
    },
  )

  return (
    <PaymentScheduleCalendar
      isLoading={isFetching}
      showChangeButton={true}
      showDisclaimer={false}
      first_payment_date={cachedSchedule?.first_payment_date}
      payment_cycle_due_date={cachedSchedule?.payment_cycle_due_date}
      schedule={cachedSchedule?.schedule}
      monthly_choice={cachedSchedule?.monthly_choice}
      withdraw_amount={cachedSchedule?.withdraw_amount}
      nextStep={StepsEnum.CREDIT_RENEWAL_REPAYMENT_SCHEDULE}
    />
  )
}
