import { captureMessage } from '@sentry/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { AgreementTypeEnum } from 'src/components/ReviewOrder/types'
import { useApi, useOriginationContext } from 'src/hooks'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { useScheduleHelper } from 'src/hooks/useScheduleHelper'
import {
  BankingType,
  CachedApplications,
  ConsentType,
  GetPaymentResponseDataType,
  StepsEnum,
} from 'src/types'
import {
  confirmLoanClicked,
  copy,
  hardCreditCheckChecked,
  loanAgreementChecked,
  loanAgreementDownloaded,
  padAgreementChecked,
  padAgreementDownloaded,
  reviewBackClicked,
  reviewMarketingConsentChecked,
} from 'src/utils'
import { pdfFileDownload } from 'src/utils/files'

export const useReviewOrder = () => {
  const { bankFormat, currencyFormat } = useLocalizedFormatters()
  const { t } = useTranslation()
  const {
    applicationId,
    cachedApplications,
    setCachedApplications,
    setStep,
    isAuthorizePaymentStartError,
    isCheckingAppStatus,
    bootstrapInfo,
  } = useOriginationContext()

  const { getAgreement, saveEmailConsent, getPaymentMethods } = useApi()

  const {
    frequency,
    monthlyChoice,
    paymentCycleDueDate,
    firstPaymentDate,
    shouldRefechPaymentSchedule,
    generateAndSaveSchedule,
    paymentCycleDueDateStr,
    cachedSchedule,
    withdrawAmount,
  } = useScheduleHelper()

  const consents: ConsentType | undefined = cachedApplications[applicationId]
    ?.consents as ConsentType
  const isPolicyIncomplete = !consents?.accept_toc || !consents?.check_fico || !consents?.accept_pad
  const consentEmailSource = cachedApplications[applicationId]?.consent_email_source ?? null

  const [isEnrolled, setIsEnrolled] = useState(false)
  const [incompleteError, setIncompleteError] = useState(false)
  const [hideSnackBar, setHideSnackBar] = useState(false)
  const [isLoadingLoanAgreement, setIsLoadingLoanAgreement] = useState(false)
  const [isLoadingPadAgreement, setIsLoadingPadAgreement] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState('')
  const [isGetPaymentLoading, setIsGetPaymentLoading] = useState(true)
  const [prevFirstPaymentDate, setPrevFirstPaymentDate] = useState<Date | null>(null)
  const [isVerifyingRequest, setIsVerifyingRequest] = useState(false)
  const [bankAccountNumber, setBankAccountNumber] = useState<string>('')

  useEffect(() => {
    if (!isPolicyIncomplete) {
      setIncompleteError(false)
    }
  }, [consents])

  useEffect(() => {
    if (bootstrapInfo?.application?.payment_protection_applied) {
      setIsEnrolled(bootstrapInfo.application.payment_protection_applied)
    }
  }, [bootstrapInfo?.application?.payment_protection_applied])

  useQuery<GetPaymentResponseDataType>(['getPaymentMethods'], () => getPaymentMethods(), {
    onSuccess: result => {
      const bankList = result.data.bank_list
      bankList.sort((a: BankingType, b: BankingType) => a.last_used?.localeCompare(b.last_used))
      const bank = bankList[0]
      setPaymentMethod(bankFormat(bank))
      setBankAccountNumber(bank.account_number)
      setIsGetPaymentLoading(false)
    },
  })

  const { refetch: refetchLoanAgreement } = useQuery(
    ['getAgreement', false, true],
    () => getAgreement({ generate_roc: true }),
    {
      enabled: false,
      onSuccess: result => {
        pdfFileDownload(result.data.data.roc_content, 'Loan Agreement.pdf')
        setIsLoadingLoanAgreement(false)
        loanAgreementDownloaded(
          t('ReviewOrder.consents.loanAgreementLabel'),
          StepsEnum.ORDER_REVIEW,
        )
      },
    },
  )

  const { refetch: refetchPadAgreement } = useQuery(
    ['getAgreement', true, false],
    () => getAgreement({ generate_pad: true }),
    {
      enabled: false,
      onSuccess: result => {
        pdfFileDownload(result.data.data.pad_content, 'PAD Agreement.pdf')
        setIsLoadingPadAgreement(false)
        padAgreementDownloaded(t('ReviewOrder.consents.padAgreementLabel'), StepsEnum.ORDER_REVIEW)
      },
    },
  )

  const completeOrder = async (nextStep: string) => {
    if (isPolicyIncomplete) {
      setIncompleteError(true)
      const errorElement = document.getElementById('errorWrapper')
      errorElement?.scrollIntoView({ behavior: 'smooth' })
      return
    }

    setPrevFirstPaymentDate(firstPaymentDate)
    if (shouldRefechPaymentSchedule) {
      await generateAndSaveSchedule({
        schedule: frequency,
        monthly_choice: monthlyChoice,
        first_payment_date: paymentCycleDueDateStr!,
      })

      captureMessage(
        `First payment date of ${firstPaymentDate} is invalid, a new repayment schedule will be calculated.`,
        {
          level: 'warning',
          extra: {
            firstPaymentDate,
            latestFirstPaymentDate: cachedSchedule?.first_payment_date,
            paymentCycleDueDate,
            latestPaymentCycleDueDate: cachedSchedule?.payment_cycle_due_date,
          },
        },
      )
      const cachedApplicationResetConsents = copy(cachedApplications) as CachedApplications
      cachedApplicationResetConsents[applicationId].consents = {
        ...consents,
        accept_toc: false,
        accept_pad: false,
        check_fico: false,
      } // reset consents
    } else {
      confirmLoanClicked(t('ReviewOrder.confirmLoanLabel'), StepsEnum.ORDER_REVIEW)
      setStep(nextStep)
    }
  }
  const fetchLoanAgreement = () => {
    setIsLoadingLoanAgreement(true)
    setHideSnackBar(false)
    refetchLoanAgreement()
  }

  const fetchPadAgreement = () => {
    setIsLoadingPadAgreement(true)
    setHideSnackBar(false)
    refetchPadAgreement()
  }

  const handleContinue = (nextStep: StepsEnum) => {
    setIsVerifyingRequest(true)
    completeOrder(nextStep).then(() => setIsVerifyingRequest(false))
  }
  const handlePrevious = (previousStep: StepsEnum) => {
    setStep(previousStep)
    reviewBackClicked(previousStep)
  }
  const updateConsents = () => {
    const updatedCachedApplications = copy(cachedApplications)
    updatedCachedApplications[applicationId].consents = {
      ...consents,
      accept_toc: false,
      accept_pad: false,
      check_fico: false,
    }
    setCachedApplications(updatedCachedApplications)
  }

  const handleAgreementChecked = (checked: boolean, agreementType: AgreementTypeEnum) => {
    let updatedConsents = { ...consents }
    switch (agreementType) {
      case AgreementTypeEnum.TOC:
        loanAgreementChecked(StepsEnum.ORDER_REVIEW)
        updatedConsents = {
          ...consents,
          accept_toc: checked,
        }
        break
      case AgreementTypeEnum.PAD:
        padAgreementChecked(StepsEnum.ORDER_REVIEW)
        updatedConsents = {
          ...consents,
          accept_pad: checked,
        }
        break
      case AgreementTypeEnum.FICO:
        hardCreditCheckChecked(StepsEnum.ORDER_REVIEW)
        updatedConsents = {
          ...consents,
          check_fico: checked,
        }
        break
      case AgreementTypeEnum.EMAIL:
        if (checked) {
          reviewMarketingConsentChecked(StepsEnum.ORDER_REVIEW)
        }
        saveEmailConsent(checked).catch(() => {
          captureMessage(`Error saving marketing consent on Review page`)
        })
        updatedConsents = {
          ...consents,
          consent_email: checked,
        }
        break
    }
    const updatedCachedApplications = copy(cachedApplications)
    updatedCachedApplications[applicationId].consents = updatedConsents
    setCachedApplications(updatedCachedApplications)
  }

  const payment_protection_tax_rate =
    (bootstrapInfo?.application?.payment_protection_tax_rate || 0) * 0.01
  const payment_protection_premium_rate =
    (bootstrapInfo?.application?.payment_protection_premium_rate || 0) * 0.01
  const paymentAmount = parseFloat(withdrawAmount!)
  const premiumAmount = paymentAmount * payment_protection_premium_rate
  const premiumTaxAmount = premiumAmount * payment_protection_tax_rate!
  const formattedPaymentAmount = currencyFormat(paymentAmount, 2)
  const formattedPremiumAmount = currencyFormat(premiumAmount, 2)
  const formattedPremiumTaxAmount = currencyFormat(premiumTaxAmount, 2)
  const formattedTotalAmount = currencyFormat(paymentAmount + premiumAmount + premiumTaxAmount, 2)
  const eligibleForProtection = bootstrapInfo?.application?.applicable_for_protection

  return {
    eligibleForProtection,
    isCheckingAppStatus,
    isEnrolled,
    isAuthorizePaymentStartError,
    isPolicyIncomplete,
    consentEmailSource,
    incompleteError,
    hideSnackBar,
    setHideSnackBar,
    isLoadingLoanAgreement,
    isLoadingPadAgreement,
    paymentMethod,
    isGetPaymentLoading,
    prevFirstPaymentDate,
    isVerifyingRequest,
    completeOrder,
    fetchLoanAgreement,
    fetchPadAgreement,
    consents,
    updateConsents,
    handleAgreementChecked,
    handleContinue,
    handlePrevious,
    paymentAmount,
    premiumAmount,
    premiumTaxAmount,
    formattedPaymentAmount,
    formattedPremiumAmount,
    formattedPremiumTaxAmount,
    formattedTotalAmount,
    bankAccountNumber,
  }
}
