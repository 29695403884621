import { Box, Link, Typography } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import { SxProps, Theme, styled } from '@mui/material/styles'
import { ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { ConnectivityToast } from 'src/components/ConnectivityToast/ConnectivityToast'
import { IconSection } from 'src/components/EndToEnd/landing/IconSection/IconSection'
import { ProgressBar } from 'src/components/ProgressBar/ProgressBar'
import FigHeader from 'src/components/common/FigHeader'
import NetworkProvider from 'src/contexts/network'
import { supportedLanguages, SupportedLanguage } from 'src/locales'
import { HeaderType, IconType, LogoType } from 'src/types'
import { PrequalificationContext } from 'src/contexts'
import { TrustpilotMiniCarousel } from 'src/components/Trustpilot'
import { FeatureFlags } from 'src/types/common'

import { MaintenanceBanner } from './MaintenanceBanner'

const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingTop: 0,
  paddingBottom: 0,
  backgroundColor: 'white',
  [theme.breakpoints.up('md')]: {
    minHeight: '100vh',
    backgroundColor: theme.color.grey2,
  },
}))

const FigAppBar = styled(AppBar)({
  height: '80px',
  background: 'transparent',
  display: 'flex',
  position: 'static',
  flexDirection: 'row',
  boxShadow: 'none',
  backgroundColor: 'white',
})

const ContainerStyle = styled(Container)(({ theme }) => ({
  padding: '0',
  [theme.breakpoints.up('md')]: {
    padding: '0 32px',
  },
}))

const ImgStyle = styled('img')(({ theme }) => ({
  height: '32px',
  width: 'auto',
  display: 'block',
  marginTop: '20px',
  marginBottom: '20px',
  marginLeft: '24px',
  marginRight: '24px',
  [theme.breakpoints.up('md')]: {
    height: '40px',
    marginLeft: '0',
    marginRight: '0',
  },
}))

const FigContentContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    width: 594,
    marginTop: '64px',
    marginBottom: '6px',
  },
}))

const FigChildrenContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: '0px',
    backgroundColor: 'white',
    border: 'solid 1px',
    borderColor: theme.color.grey4,
    borderRadius: '8px',
  },
}))

const TrustPilotContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  background: theme.color.brand7,
  padding: '16px',
  [theme.breakpoints.up('md')]: {
    marginTop: '20px',
    width: 594,
    marginBottom: '64px',
  },
}))

const selectedLanguageClass = 'selectedLanguage'

const LanguageLink = styled(Link)({
  fontWeight: 'normal',
  [`&.${selectedLanguageClass}`]: {
    fontWeight: 'bold',
  },
  ':not(:last-child):after': {
    content: '" | "',
    fontWeight: 'normal',
  },
})

const LanguageToggle = () => {
  const { i18n } = useTranslation()

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Typography textAlign="right">
        {supportedLanguages.map(lang => (
          <LanguageLink
            key={lang}
            className={i18n.resolvedLanguage === lang ? selectedLanguageClass : ''}
            onClick={() => {
              if (lang !== i18n.resolvedLanguage) {
                i18n.changeLanguage(lang)
              }
            }}
          >
            {lang.toUpperCase()}
          </LanguageLink>
        ))}
      </Typography>
    </Box>
  )
}

const AboveContainerContent = () => {
  // content should exist for a specific page
  // if it doesn't exist, it should return null
  return null
}

export default RootLayout
/**
 *
 */
function RootLayout({
  children,
  logo,
  header,
  iconConfig,
  showProgressBar = true,
  contentStyles,
}: {
  children: ReactNode
  logo: LogoType
  header?: HeaderType
  iconConfig?: IconType
  contentStyles?: SxProps<Theme>
  showProgressBar?: boolean
}) {
  const { i18n } = useTranslation()
  const language = i18n.resolvedLanguage as SupportedLanguage
  const { partnerConfig, trustPilotWidgetPlacementLanding } = useContext(PrequalificationContext)
  const { isMaintenanceBanner } = useFlags<FeatureFlags>()

  return (
    <StyledDiv id="fig-root-container">
      {isMaintenanceBanner ? <MaintenanceBanner /> : null}
      <FigAppBar>
        <ContainerStyle maxWidth={false}>
          {trustPilotWidgetPlacementLanding === 'none' ? (
            logo ? (
              <ImgStyle src={logo.src} alt={logo.alt} data-testid="partnerLogo" />
            ) : null
          ) : (
            <a href={`https://fig.ca/${language}/`} target="_blank" rel="noreferrer">
              {logo ? <ImgStyle src={logo.src} alt={logo.alt} data-testid="partnerLogo" /> : null}
            </a>
          )}
        </ContainerStyle>
        <LanguageToggle />
      </FigAppBar>
      {showProgressBar && <ProgressBar />}
      {header ? <FigHeader {...header} /> : null}
      {iconConfig && Object.keys(iconConfig).length ? (
        <IconSection mobileIcon={iconConfig.mobileIcon} desktopIcon={iconConfig.desktopIcon} />
      ) : null}
      <NetworkProvider>
        <ConnectivityToast />
        <FigContentContainer sx={contentStyles}>
          <AboveContainerContent />
          <FigChildrenContainer id="fig-content-container">{children}</FigChildrenContainer>
        </FigContentContainer>
      </NetworkProvider>
      {!partnerConfig?.is_referral && trustPilotWidgetPlacementLanding === 'bottom' && (
        <TrustPilotContainer>
          <TrustpilotMiniCarousel />
        </TrustPilotContainer>
      )}
    </StyledDiv>
  )
}
