import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { DivRoot } from 'src/components/common/DivRoot'
import { PrimaryCTA } from 'src/components/common/Buttons'
import { StepsEnum } from 'src/types'
import { useOriginationContext } from 'src/hooks'
import { creditRenewalsLoanDetailsPageCTAClicked } from 'src/utils'
import { useSelectOffer } from 'src/components/CreditRenewal/hooks/useSelectOffer'

import { PaymentCalendarComponent } from './components/LoanDetails/PaymentCalendarComponent'
import { LoanAmountComponent } from './components/LoanDetails/LoanAmountComponent'
import { SelectOfferComponent } from './components/LoanDetails/SelectOfferComponent'

interface LoanDetailsProps {
  nextStep: StepsEnum
}

function LoanDetails({ nextStep }: LoanDetailsProps) {
  const { maxLoanAmount, setStep, offerList, cachedApplication, updateAsyncRequestStatusCallback } =
    useOriginationContext()

  const { t } = useTranslation()
  const selectedLoanAmount = cachedApplication.selected_loan_amount || maxLoanAmount
  const selectedOfferTerm = cachedApplication.selected_offer_term as number

  const { saveOfferTermMutation } = useSelectOffer()

  const confirmOffer = (): void => {
    updateAsyncRequestStatusCallback('saveOffer', () =>
      saveOfferTermMutation({
        loan_amount: selectedLoanAmount,
        term: selectedOfferTerm,
        estimated_due_amount: offerList.filter(o => o.term_length === selectedOfferTerm)[0]
          ?.payment_amount,
      }),
    )
    setStep(nextStep)
  }

  return (
    <DivRoot>
      <Grid container spacing={0}>
        <LoanAmountComponent />
        <SelectOfferComponent />
        <Grid item xs={12} sx={{ paddingTop: '32px' }}>
          <PaymentCalendarComponent />
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '32px' }}>
          <PrimaryCTA
            onClick={() => {
              confirmOffer()
              creditRenewalsLoanDetailsPageCTAClicked(
                t('CreditRenewals.LoanDetails.buttonLabel'),
                StepsEnum.CREDIT_RENEWAL_LOAN_DETAILS,
              )
            }}
            buttonText={t('CreditRenewals.LoanDetails.buttonLabel')}
          />
        </Grid>
      </Grid>
    </DivRoot>
  )
}

export default LoanDetails
