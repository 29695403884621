import { Box, Grid, styled, MenuItem } from '@mui/material'
import Typography from '@mui/material/Typography'
import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import { Field, Form, Formik, FormikErrors } from 'formik'
import * as Yup from 'yup'
import { useIsMutating, useMutation } from 'react-query'
import { captureMessage } from '@sentry/react'
import isEmail from 'validator/lib/isEmail'
import isPostalCode from 'validator/lib/isPostalCode'
import { useTranslation } from 'react-i18next'
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined'

import { AutoSave } from 'src/components/EndToEnd/AutoSave'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { DivRoot } from 'src/components/common/DivRoot'
import theme from 'src/themes'
import { ReactComponent as FlatIndicator } from 'src/images/flatIndicator.svg'
import { ReactComponent as VerticalIndicator } from 'src/images/veritcalIndicator.svg'
import { FigFormControl } from 'src/components/common/FigFormControl'
import { FigDropdown } from 'src/components/common/FigDropdown'
import { StyledDivider } from 'src/components/common/StyledDivider'
import FigToggleButtonGroup from 'src/components/common/FigToggleButton'
import { PrimaryCTA } from 'src/components/common/Buttons'
import { AutoSaveContext } from 'src/contexts/prequalification'
import {
  ApplicationStatus,
  DeclineCodes,
  FigAutocompleteAddress,
  PrequalRequestType,
  PrequalResponseDataType,
  ResidentialStatus,
  StepsEnum,
} from 'src/types'
import {
  FigDatePickerDay,
  FigDatePickerMonth,
  getIsDateInFuture,
} from 'src/components/common/FigDatePicker'
import FigTextField from 'src/components/common/FigTextField'
import { CSS_CLASS_NAMES, IS_PRODUCTION, ENVIRONMENT, NAME_REGEX } from 'src/utils/constants'
import { PrequalificationContext } from 'src/contexts'
import { dateISOFormat, formatPostalCode, formatSIN } from 'src/utils/format'
import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { logoImgSvg } from 'src/images'
import RootLayout from 'src/components/common/RootLayout'
import { FigAddressSearchField } from 'src/components/common/FigAddressSearch'
import useApiE2E from 'src/hooks/useApiE2E'
import { provinceIsQC } from 'src/utils/borrower'
import { AlertColor, FigAlert } from 'src/components/common/FigAlert'
import { SupportedLanguage } from 'src/locales'
import { useConfiguration } from 'src/hooks'
import { captureExceptionHelper } from 'src/utils'
import { applicantData } from 'src/data/mock/mockApplicantData'

const ConsentTypography = styled(Typography)({
  fontWeight: 400,
  color: theme.color.grey6,
  fontSize: '12px',
  lineHeight: '18px',
})

function Consents() {
  const { t } = useTranslation()
  return (
    <>
      <Grid item xs={12} marginTop="18px" textAlign="center">
        <ConsentTypography>{t('e2e.Prequalification.consents.paragraph1')}</ConsentTypography>
      </Grid>
      <Grid item xs={12} marginTop="9px" textAlign="center">
        <ConsentTypography>{t('e2e.Prequalification.consents.paragraph2')}</ConsentTypography>
      </Grid>
    </>
  )
}

const MemoizedFigTextField = memo(FigTextField)

function Banner() {
  const { t } = useTranslation()
  const { currencyFormat, percentFormat } = useLocalizedFormatters()
  const { loanLimits } = useConfiguration()
  return (
    <Grid
      item
      xs={12}
      sx={{
        backgroundColor: theme.color.brand5,
        padding: '12px',
        borderTopLeftRadius: '7px',
        borderTopRightRadius: '7px',
      }}
      textAlign="center"
    >
      <Typography variant="linkMedium" sx={{ fontWeight: 500 }}>
        {t('e2e.Prequalification.banner', {
          amt: currencyFormat(loanLimits?.maxLoanAmount, 0),
          interest: percentFormat(loanLimits?.minApr),
        })}
      </Typography>
    </Grid>
  )
}

const validationSchema = Yup.object({
  firstName: Yup.string()
    .matches(new RegExp(NAME_REGEX), 'e2e.Prequalification.form.personalDetails.firstName.invalid')
    .max(40, 'e2e.Prequalification.form.personalDetails.firstName.max')
    .required('e2e.Prequalification.form.personalDetails.firstName.required'),
  lastName: Yup.string()
    .matches(new RegExp(NAME_REGEX), 'e2e.Prequalification.form.personalDetails.lastName.invalid')
    .min(2, 'e2e.Prequalification.form.personalDetails.lastName.min')
    .max(80, 'e2e.Prequalification.form.personalDetails.lastName.max')
    .required('e2e.Prequalification.form.personalDetails.lastName.required'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, 'e2e.Prequalification.form.personalDetails.phoneNumber.invalid')
    .min(10, 'e2e.Prequalification.form.personalDetails.phoneNumber.length')
    .max(10, 'e2e.Prequalification.form.personalDetails.phoneNumber.length')
    .required('e2e.Prequalification.form.personalDetails.phoneNumber.required'),
  email: Yup.string()
    .test('email-valid', 'e2e.Prequalification.form.personalDetails.email.invalid', value =>
      isEmail(value ?? ''),
    )
    .required('e2e.Prequalification.form.personalDetails.email.required'),
  grossAnnualIncome: Yup.string()
    .matches(/^[0-9]+$/, 'e2e.Prequalification.form.personalDetails.grossAnnualIncome.invalid')
    .required('e2e.Prequalification.form.personalDetails.grossAnnualIncome.required'),
  socialInsuranceNumber: Yup.string()
    .matches(/^[0-9]+$/, 'e2e.Prequalification.form.personalDetails.socialInsuranceNumber.invalid')
    .min(9, 'e2e.Prequalification.form.personalDetails.socialInsuranceNumber.length')
    .max(9, 'e2e.Prequalification.form.personalDetails.socialInsuranceNumber.length'),
  birthDate: Yup.date()
    .nullable()
    .typeError('e2e.Prequalification.form.personalDetails.birthDate.invalid')
    .min(new Date('1900-01-01'), 'e2e.Prequalification.form.personalDetails.birthDate.invalid')
    .max(new Date(), 'e2e.Prequalification.form.personalDetails.birthDate.invalid')
    .required('e2e.Prequalification.form.personalDetails.birthDate.required'),
  address: Yup.object()
    .shape({
      address_1: Yup.string().when('country_code', {
        is: (val: string) => !!val,
        then: schema => schema.required('e2e.Prequalification.form.address.errors.invalid'),
        otherwise: schema => schema.required('e2e.Prequalification.form.address.errors.required'),
      }),
      postal_code: Yup.string()
        .test('postal-code-valid', 'e2e.Prequalification.form.address.errors.invalid', value =>
          isPostalCode(value ?? '', 'CA'),
        )
        .required('e2e.Prequalification.form.address.errors.invalid'),
      province_code: Yup.string().required('e2e.Prequalification.form.address.errors.invalid'),
      city: Yup.string().required('e2e.Prequalification.form.address.errors.invalid'),
      country_code: Yup.string().required('e2e.Prequalification.form.address.errors.required'),
    })
    .required('e2e.Prequalification.form.address.errors.required'),
  unit: Yup.string(),
  postalCode: Yup.string()
    .test('postal-code-valid', 'e2e.Prequalification.form.address.postalCode.invalid', value =>
      isPostalCode(value ?? '', 'CA'),
    )
    .required('e2e.Prequalification.form.address.postalCode.required'),
  city: Yup.string().required('e2e.Prequalification.form.address.city.required'),
  monthlyHomePayment: Yup.string()
    .matches(/^[0-9]+$/, 'e2e.Prequalification.form.address.monthlyHomePayment.invalid')
    .required('e2e.Prequalification.form.address.monthlyHomePayment.required'),
  homeOwnershipStatus: Yup.string().required(),
  homeMoveInDate: Yup.date()
    .nullable()
    .typeError('e2e.Prequalification.form.address.homeMoveInDate.invalid')
    .max(new Date(), 'e2e.Prequalification.form.address.homeMoveInDate.invalid')
    .required('e2e.Prequalification.form.address.homeMoveInDate.required'),
  isEmailConsent: Yup.boolean(),
})

export type PrequalificationForm = {
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  grossAnnualIncome: string
  socialInsuranceNumber: string
  birthDate: Date | null
  address: FigAutocompleteAddress
  postalCode: string
  city: string
  unit: string
  monthlyHomePayment: string
  homeOwnershipStatus: string
  homeMoveInDate: Date | null
  isEmailConsent: boolean
}

export type PrequalificationFormTouched = {
  [K in keyof PrequalificationForm]: boolean
}

const MANUAL_ADDRESS_FALL_BACK_ERRORS = ['postal_code', 'city']
function hasManualAddressError(addressError: FormikErrors<FigAutocompleteAddress>) {
  return Object.keys(addressError).every(addrErr =>
    MANUAL_ADDRESS_FALL_BACK_ERRORS.includes(addrErr),
  )
}

/**
 *
 */
export function Prequalification() {
  const [homeMoveInDateFocused, setHomeMoveInDateFocused] = useState(false)
  const [birthDateFocused, setBirthDateFocused] = useState(false)
  const [isTestFormUsed, setIsTestFormUsed] = useState(false)

  const {
    setPrequalResponse,
    setFirstName,
    setIsQCBorrower,
    setStepE2E,
    partnerSessionId,
    campaignId,
    publisherId,
    prequalResponse,
    isQCConsentGiven,
    jwtApiKey,
    e2eHomeStep,
    useTestForm,
    queryParams,
    setPrequalGenericErrorPageError,
  } = useContext(PrequalificationContext)
  const {
    prequalFormInProgressTouched,
    prequalFormInProgress,
    isManualAddressError,
    setIsManualAddressError,
    setPrequalFormInProgress,
    setSelectedAddress,
  } = useContext(AutoSaveContext)

  const initialValues: PrequalificationForm = prequalFormInProgress || {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    grossAnnualIncome: '',
    socialInsuranceNumber: '',
    birthDate: null,
    address: {
      formattedAddress: '',
      address_1: '',
      address_2: '',
      postal_code: '',
      province_code: '',
      city: '',
      country_code: '',
    },
    postalCode: '',
    city: '',
    unit: '',
    monthlyHomePayment: '',
    homeOwnershipStatus: ResidentialStatus.RENT,
    homeMoveInDate: null,
    isEmailConsent: false,
  }

  const initialTouched: PrequalificationFormTouched = prequalFormInProgressTouched || {
    firstName: false,
    lastName: false,
    phoneNumber: false,
    email: false,
    grossAnnualIncome: false,
    socialInsuranceNumber: false,
    birthDate: false,
    address: false,
    postalCode: false,
    city: false,
    unit: false,
    monthlyHomePayment: false,
    homeOwnershipStatus: false,
    homeMoveInDate: false,
    isEmailConsent: false,
  }

  const { prequalify } = useApiE2E()
  const { t, i18n } = useTranslation()
  const language = i18n.resolvedLanguage as SupportedLanguage

  const containerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const container = containerRef.current
    if (container) {
      container.scrollIntoView({ behavior: 'smooth' })
    }
  }, [prequalResponse?.status])

  useEffect(() => {
    if (e2eHomeStep !== null && jwtApiKey !== null && !isQCConsentGiven) {
      setStepE2E(e2eHomeStep)
    }
  }, [isQCConsentGiven, jwtApiKey, e2eHomeStep])

  const handleAddressChange = async ({
    addressValue,
    addressError,
    setFieldValue,
  }: {
    addressValue: FigAutocompleteAddress
    addressError: FormikErrors<FigAutocompleteAddress> | undefined
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
      ): Promise<void | FormikErrors<PrequalificationForm>>
    }
  }) => {
    const unit = parseAddress2(addressValue.address_2) ?? ''
    const city = addressValue.city ?? ''
    const postalCode = addressValue.postal_code ?? ''

    await setFieldValue('unit', unit)
    await setFieldValue('city', city)
    await setFieldValue('postalCode', postalCode)

    const isManualAddressError = addressError ? hasManualAddressError(addressError) : false
    setIsManualAddressError(isManualAddressError)
  }

  const parseAddress2 = (inputValue?: string) => {
    if (!inputValue) {
      return undefined
    }
    const splitStr = inputValue.split(/(\d.*)/, 2) // split on first digit, only return 2 items
    const parsedUnit = splitStr[1] ?? splitStr[0] // if we didn't find a digit, return the first section
    return parsedUnit
  }

  const { mutate: prequalMutation } = useMutation<PrequalResponseDataType, any, PrequalRequestType>(
    prequalify,
    {
      useErrorBoundary: false,
      onSuccess: (result: PrequalResponseDataType) => {
        setPrequalResponse(result.data)

        if (result.data.status === ApplicationStatus.DECLINED) {
          if (result.data.is_second_look_approved) {
            setStepE2E(StepsEnum.SECOND_LOOK_OFFER)
            setPrequalFormInProgress(null)
            setSelectedAddress(null)
            return
          }
          if (!result.data.decline_message) {
            // if we have a message, we can stay on this page and display a toast, otherwise we should route to declined screen
            setStepE2E(StepsEnum.DECLINED)
            setPrequalFormInProgress(null)
            setSelectedAddress(null)
          }
          return
        }
        if (result.data.status === ApplicationStatus.PRE_AUTHORIZED) {
          setStepE2E(StepsEnum.OFFER)
          setPrequalFormInProgress(null)
          setSelectedAddress(null)
          return
        }
        setPrequalGenericErrorPageError(
          new Error(
            `Error on prequal ${ENVIRONMENT}, application status incorrect, status = ${result.data.status}`,
          ),
        )
        setStepE2E(StepsEnum.ERROR)
        captureMessage(
          `Error on prequal ${ENVIRONMENT}, application status incorrect, status = ${result.data.status}`,
          'error',
        )
      },
      onError: error => {
        captureExceptionHelper(`Error on prequal ${ENVIRONMENT}`, error)
        setPrequalGenericErrorPageError(new Error(`Error on prequal ${ENVIRONMENT}`))
        setStepE2E(StepsEnum.ERROR)
      },
    },
  )

  const isLoading = useIsMutating()

  const handleSubmit = (values: PrequalificationForm) => {
    const { address_1, province_code, city, country_code, postal_code } = values.address
    const parsedUnit = parseAddress2(values.unit)
    setIsQCBorrower(provinceIsQC(province_code))
    setFirstName(values.firstName)
    prequalMutation({
      borrower: {
        home_address: {
          address_1: address_1,
          address_2: parsedUnit ? `Unit ${parsedUnit}` : undefined,
          province_code: province_code,
          city: city,
          country_code: country_code,
          postal_code: formatPostalCode(postal_code),
        },
        personal_info: {
          legal_first_name: values.firstName,
          legal_last_name: values.lastName,
          mobile_number: values.phoneNumber,
          email_address: values.email,
          date_of_birth: dateISOFormat(values.birthDate),
          social_insurance_number: formatSIN(values.socialInsuranceNumber),
        },
        annual_gross_income: Number.parseInt(values.grossAnnualIncome),
        is_homeowner: values.homeOwnershipStatus === ResidentialStatus.OWN,
        monthly_home_payment: Number.parseInt(values.monthlyHomePayment),
        years_at_address: new Date().getFullYear() - values.homeMoveInDate!.getFullYear(),
      },
      locale: language,
      is_email_consent: values.isEmailConsent,
      partner_session_id: partnerSessionId ?? null,
      campaign_id: campaignId ?? null,
      publisher_id: publisherId ?? null,
      is_test_form_used: isTestFormUsed ?? false,
      query_params: queryParams || null,
    })
  }

  const fillFormWithTestData = (
    applicantName: string,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
      ): Promise<void | FormikErrors<PrequalificationForm>>
    },
  ) => {
    const testData = applicantData[applicantName]
    setIsManualAddressError(true)
    setIsTestFormUsed(true)

    setFieldValue('firstName', testData.firstName)
    setFieldValue('lastName', testData.lastName)
    setFieldValue('birthDate', testData.birthDate)
    setFieldValue('phoneNumber', testData.phoneNumber)
    setFieldValue('email', testData.email)
    setFieldValue('grossAnnualIncome', testData.grossAnnualIncome)
    setFieldValue('address', testData.address)
    setFieldValue('city', testData.address.city)
    setFieldValue('postalCode', testData.address.postal_code)
    setFieldValue('monthlyHomePayment', testData.monthlyHomePayment)
    setFieldValue('homeMoveInDate', testData.homeMoveInDate)
    setFieldValue('homeOwnershipStatus', testData.homeOwnershipStatus)
  }

  const isDuplicateEmailError =
    prequalResponse?.status === ApplicationStatus.DECLINED &&
    prequalResponse?.decline_code == DeclineCodes.EXISTING_EMAIL
  const isDuplicatePhoneError =
    prequalResponse?.status === ApplicationStatus.DECLINED &&
    prequalResponse?.decline_code == DeclineCodes.EXISTING_PHONE
  const isDuplicateEmailPhoneError =
    prequalResponse?.status === ApplicationStatus.DECLINED &&
    prequalResponse?.decline_code == DeclineCodes.EXISTING_EMAIL_PHONE

  return (
    <Formik
      onSubmit={handleSubmit}
      {...{ initialValues, initialTouched, validationSchema }}
      validateOnMount={true}
    >
      {({ isValid, handleBlur, touched, errors, values, setFieldValue }) => (
        <RootLayout
          logo={{ src: logoImgSvg, alt: 'Fig logo' }}
          header={
            !isLoading
              ? {
                  title: t('e2e.Prequalification.title'),
                  content: t('e2e.Prequalification.subtitle'),
                  sx: {
                    [theme.breakpoints.up('md')]: { marginTop: '64px' },
                  },
                }
              : undefined
          }
          showProgressBar={false}
        >
          <Box>
            {!isLoading && !IS_PRODUCTION && useTestForm && (
              <Grid item margin="15px">
                <FigDropdown
                  placeholder={'Select an applicant to fill form'}
                  onChange={e => fillFormWithTestData(e.target.value as string, setFieldValue)}
                  label={'Fill Form w/ Test Data'}
                  menuItems={Object.keys(applicantData).map(applicantName => (
                    <MenuItem key={applicantName} value={applicantName}>
                      {applicantName}
                    </MenuItem>
                  ))}
                />
              </Grid>
            )}
            {!isLoading && <Banner />}
            <DivRoot sx={{ [theme.breakpoints.up('md')]: { paddingTop: '50px' } }}>
              <Form>
                {isLoading ? (
                  <LoadingAnimation subtitle={t('e2e.Prequalification.loading')} />
                ) : (
                  <Grid container spacing={0}>
                    {prequalResponse?.status === ApplicationStatus.DECLINED &&
                      prequalResponse.decline_message && (
                        <Grid container marginBottom={'50px'} ref={containerRef}>
                          <FigAlert
                            color={AlertColor.ERROR}
                            icon={<ReportOutlinedIcon />}
                            title={t('e2e.Prequalification.alert.error.title')}
                            content={prequalResponse.decline_message[`affiliate_msg_${language}`]}
                            alertSx={{ position: 'static', width: '100%' }}
                            contentSx={{ maxWidth: 'inherit' }}
                          />
                        </Grid>
                      )}
                    <AutoSave />
                    <Grid item xs={12}>
                      <VerticalIndicator />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h1" sx={{ fontWeight: 500 }} textAlign="left">
                        {t('e2e.Prequalification.form.personalDetails.title')}
                      </Typography>
                      <Grid container spacing={1} marginTop="12px">
                        <Grid item xs={6}>
                          <MemoizedFigTextField
                            name="firstName"
                            label={t('e2e.Prequalification.form.personalDetails.firstName.label')}
                            value={values.firstName}
                            onChange={e => setFieldValue('firstName', e.target.value)}
                            onBlur={handleBlur}
                            error={!!errors.firstName && touched.firstName}
                            helperText={
                              errors.firstName && touched.firstName ? t(errors.firstName, '') : ''
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <MemoizedFigTextField
                            name="lastName"
                            label={t('e2e.Prequalification.form.personalDetails.lastName.label')}
                            value={values.lastName}
                            onChange={e => setFieldValue('lastName', e.target.value)}
                            onBlur={handleBlur}
                            error={!!errors.lastName && touched.lastName}
                            helperText={
                              errors.lastName && touched.lastName ? t(errors.lastName, '') : ''
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} marginTop="22px">
                        <FigDatePickerDay
                          label={t('e2e.Prequalification.form.personalDetails.birthDate.label')}
                          error={!!errors.birthDate && touched.birthDate}
                          helperText={
                            errors.birthDate && touched.birthDate ? t(errors.birthDate, '') : ''
                          }
                          isFocused={birthDateFocused}
                          name="birthDate"
                          getIsInvalidDate={getIsDateInFuture}
                          onBlur={e => {
                            handleBlur(e)
                            setBirthDateFocused(false)
                          }}
                          onFocus={() => setBirthDateFocused(true)}
                          readOnly={false}
                          disabled={false}
                          placeholder={'DD/MM/YYYY'}
                          inputFormat={'dd/MM/yyyy'}
                          calendarDate={values.birthDate}
                          setCalendarDate={value => setFieldValue('birthDate', value)}
                          disableWeekends={false}
                        />
                      </Grid>
                      <Grid item xs={12} marginTop="22px">
                        <MemoizedFigTextField
                          label={t('e2e.Prequalification.form.personalDetails.phoneNumber.label')}
                          name="phoneNumber"
                          value={values.phoneNumber}
                          onChange={e => setFieldValue('phoneNumber', e.target.value)}
                          onBlur={handleBlur}
                          error={
                            (!!errors.phoneNumber && touched.phoneNumber) ||
                            isDuplicatePhoneError ||
                            isDuplicateEmailPhoneError
                          }
                          helperText={
                            errors.phoneNumber && touched.phoneNumber
                              ? t(errors.phoneNumber, '')
                              : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={12} marginTop="22px">
                        <MemoizedFigTextField
                          label={t('e2e.Prequalification.form.personalDetails.email.label')}
                          name="email"
                          value={values.email}
                          onChange={e => setFieldValue('email', e.target.value)}
                          onBlur={handleBlur}
                          error={
                            (!!errors.email && touched.email) ||
                            isDuplicateEmailError ||
                            isDuplicateEmailPhoneError
                          }
                          helperText={errors.email && touched.email ? t(errors.email, '') : ''}
                        />
                      </Grid>
                      <Grid item xs={12} marginTop="22px">
                        <MemoizedFigTextField
                          label={t(
                            'e2e.Prequalification.form.personalDetails.grossAnnualIncome.label',
                          )}
                          name="grossAnnualIncome"
                          value={values.grossAnnualIncome}
                          onChange={e => setFieldValue('grossAnnualIncome', e.target.value)}
                          onBlur={handleBlur}
                          error={!!errors.grossAnnualIncome && touched.grossAnnualIncome}
                          helperText={
                            errors.grossAnnualIncome && touched.grossAnnualIncome
                              ? t(errors.grossAnnualIncome, '')
                              : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={12} marginTop="22px">
                        <MemoizedFigTextField
                          label={t(
                            'e2e.Prequalification.form.personalDetails.socialInsuranceNumber.label',
                          )}
                          name="socialInsuranceNumber"
                          value={values.socialInsuranceNumber}
                          onChange={e => setFieldValue('socialInsuranceNumber', e.target.value)}
                          onBlur={handleBlur}
                          error={!!errors.socialInsuranceNumber && touched.socialInsuranceNumber}
                          helperText={
                            errors.socialInsuranceNumber && touched.socialInsuranceNumber
                              ? t(errors.socialInsuranceNumber, '')
                              : ''
                          }
                        />
                      </Grid>
                    </Grid>

                    <StyledDivider />
                    <Grid item xs={12}>
                      <FlatIndicator />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="h1" sx={{ fontWeight: 500 }} textAlign="left">
                        {t('e2e.Prequalification.form.address.title')}
                      </Typography>
                      <Grid container spacing={1} marginTop="12px">
                        <Grid item xs={12}>
                          <Field
                            name="address"
                            onBlur={(e: any) => {
                              handleBlur(e)
                              handleAddressChange({
                                addressValue: values.address,
                                addressError: errors.address,
                                setFieldValue,
                              })
                            }}
                            component={FigAddressSearchField}
                            label={t('e2e.Prequalification.form.address.address.label')}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MemoizedFigTextField
                            label={t('e2e.Prequalification.form.address.unit.label')}
                            name="unit"
                            value={values.unit}
                            onChange={e => setFieldValue('unit', e.target.value)}
                          />
                        </Grid>
                      </Grid>
                      {isManualAddressError && (
                        <Grid container spacing={1} marginTop="22px">
                          <Grid item xs={6}>
                            <MemoizedFigTextField
                              label={t('e2e.Prequalification.form.address.city.label')}
                              name="city"
                              value={values.city}
                              onChange={e => {
                                setFieldValue('city', e.target.value)
                              }}
                              onBlur={(e: any) => {
                                handleBlur(e)
                                setFieldValue('address', {
                                  ...values.address,
                                  city: values.city,
                                })
                              }}
                              error={!!errors?.city}
                              helperText={errors.city && touched.city ? t(errors.city, '') : ''}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <MemoizedFigTextField
                              label={t('e2e.Prequalification.form.address.postalCode.label')}
                              name="postalCode"
                              value={values.postalCode}
                              onChange={e => {
                                setFieldValue('postalCode', e.target.value)
                              }}
                              onBlur={(e: any) => {
                                handleBlur(e)
                                setFieldValue('address', {
                                  ...values.address,
                                  postal_code: values.postalCode,
                                })
                              }}
                              error={!!errors?.postalCode}
                              helperText={
                                errors.postalCode && touched.postalCode
                                  ? t(errors.postalCode, '')
                                  : ''
                              }
                            />
                          </Grid>
                        </Grid>
                      )}

                      <Grid item xs={12} marginTop="22px">
                        <FigFormControl
                          label={t('e2e.Prequalification.form.address.homeOwnershipStatus.label')}
                        >
                          <FigToggleButtonGroup
                            value={values.homeOwnershipStatus}
                            setValue={value => setFieldValue('homeOwnershipStatus', value)}
                            buttonDetails={[
                              {
                                text: t(
                                  'e2e.Prequalification.form.address.homeOwnershipStatus.rent',
                                ),
                                value: ResidentialStatus.RENT,
                              },
                              {
                                text: t(
                                  'e2e.Prequalification.form.address.homeOwnershipStatus.own',
                                ),
                                value: ResidentialStatus.OWN,
                              },
                              {
                                text: t(
                                  'e2e.Prequalification.form.address.homeOwnershipStatus.other',
                                ),
                                value: ResidentialStatus.OTHER,
                              },
                            ]}
                          />
                        </FigFormControl>
                      </Grid>
                      <Grid item xs={12} marginTop="22px">
                        <MemoizedFigTextField
                          label={t('e2e.Prequalification.form.address.monthlyHomePayment.label')}
                          name="monthlyHomePayment"
                          value={values.monthlyHomePayment}
                          onChange={e => setFieldValue('monthlyHomePayment', e.target.value)}
                          onBlur={handleBlur}
                          error={!!errors.monthlyHomePayment && touched.monthlyHomePayment}
                          helperText={
                            errors.monthlyHomePayment && touched.monthlyHomePayment
                              ? t(errors.monthlyHomePayment, '')
                              : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={12} marginTop="22px">
                        <FigDatePickerMonth
                          label={t('e2e.Prequalification.form.address.homeMoveInDate.label')}
                          error={!!errors.homeMoveInDate && touched.homeMoveInDate}
                          helperText={
                            errors.homeMoveInDate && touched.homeMoveInDate
                              ? t(errors.homeMoveInDate, '')
                              : ''
                          }
                          isFocused={homeMoveInDateFocused}
                          name="homeMoveInDate"
                          getIsInvalidDate={getIsDateInFuture}
                          readOnly={false}
                          disabled={false}
                          onBlur={e => {
                            handleBlur(e)
                            setHomeMoveInDateFocused(false)
                          }}
                          onFocus={() => setHomeMoveInDateFocused(true)}
                          placeholder={'MM/YYYY'}
                          inputFormat={'MM/yyyy'}
                          calendarDate={values.homeMoveInDate}
                          setCalendarDate={value => setFieldValue('homeMoveInDate', value)}
                          disableWeekends={false}
                        />
                      </Grid>
                    </Grid>
                    <StyledDivider />
                    <Grid item xs={12}>
                      <PrimaryCTA
                        buttonText={t('e2e.Prequalification.getOffer')}
                        disabled={!isValid}
                        type="submit"
                        className={CSS_CLASS_NAMES.PREQUAL_BUTTON_CLICK_CLASS}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Consents />
                    </Grid>
                  </Grid>
                )}
              </Form>
            </DivRoot>
          </Box>
        </RootLayout>
      )}
    </Formik>
  )
}
