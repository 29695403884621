import { useEffect } from 'react'
import { useQuery } from 'react-query'

import useApi from 'src/hooks/useApi'
import useOriginationContext from 'src/hooks/useOriginationContext'
import { StepsEnum } from 'src/types'
import { creditRenewalsEligibilityPageLoaded } from 'src/utils'

/**
 * Custom hook for checking eligibility for credit renewal.
 *
 * This hook will:
 * 1. track the page load event for the eligibility screen.
 * 2. fetches data from the API, updates the state creditRenewalDecision
 * 3. redirects to the appropriate screen based on the API response.
 *
 * @returns
 * - data: The fetched data.
 * - isLoading: The loading state.
 * - t: The translation function.
 * - checkEligibility: A function to trigger the API call to check eligibility.
 */
export const useEligibility = () => {
  const { applicationId, setStep, setCreditRenewalDecision } = useOriginationContext()
  const { creditRenewalCheckEligibility } = useApi()

  useEffect(() => {
    creditRenewalsEligibilityPageLoaded('Page Loaded', StepsEnum.CREDIT_RENEWAL_ELIGIBILITY)
  }, [])

  const {
    data,
    isLoading,
    error,
    refetch: checkEligibility,
  } = useQuery(
    ['eligibility-check', applicationId],
    () => creditRenewalCheckEligibility().then(res => res.data),
    {
      enabled: false,
      useErrorBoundary: false,
    },
  )

  useEffect(() => {
    if (error) {
      setStep(StepsEnum.ERROR)
    }
  }, [error])

  useEffect(() => {
    if (!data) {
      return
    }

    const { eligible_for_renewal, decline_reasons } = data

    setCreditRenewalDecision({ eligible_for_renewal, decline_reasons })

    if (!eligible_for_renewal) {
      if (decline_reasons.includes('payment_pending') || decline_reasons.includes('declined')) {
        setStep(StepsEnum.CREDIT_RENEWAL_DECLINED)
      } else {
        setStep(StepsEnum.ERROR)
      }
    } else {
      setStep(StepsEnum.CREDIT_RENEWAL_CONFIRM_INFORMATION)
    }
  }, [data, setStep])

  return { data, isLoading, checkEligibility }
}
