import { useEffect } from 'react'
import { useMutation } from 'react-query'

import { OfferItemType } from 'src/types'
import { useScheduleHelper } from 'src/hooks/useScheduleHelper'
import { useApi, useOriginationContext } from 'src/hooks'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { generateOffers } from 'src/utils/amortization'
import { selectOfferChanged } from 'src/utils/analytics'
import { isQCResident } from 'src/utils/borrower'
import { getRetryQueryOptionsWithErrorStep } from 'src/api/api'

export const useSelectOffer = () => {
  const { generateAndSaveSchedule } = useScheduleHelper()
  const {
    bootstrapInfo,
    maxLoanAmount,
    setStep,
    offerList,
    cachedApplication,
    setOfferList,
    updateCachedApplication,
    updateAsyncRequestStatusCallback,
    setGenericErrorPageError,
  } = useOriginationContext()

  const { saveOfferTerm } = useApi()
  const { currencyFormat, percentFormat } = useLocalizedFormatters()
  const apr = bootstrapInfo?.application?.apr as number
  const selectedLoanAmount = cachedApplication.selected_loan_amount || maxLoanAmount
  const selectedOfferTerm = cachedApplication.selected_offer_term as number
  const { borrower } = bootstrapInfo

  const { mutateAsync: saveOfferTermMutation } = useMutation(saveOfferTerm, {
    onSuccess: ({ data }) => {
      updateCachedApplication({
        selected_offer_id: data?.data?.offer_id,
        saved_repayment_schedule: null,
      })
      updateAsyncRequestStatusCallback('getRepaymentSchedule', () => generateAndSaveSchedule())
    },
    ...getRetryQueryOptionsWithErrorStep({
      queryName: 'saveOfferTerm',
      setGenericErrorPageError,
      setStep,
    }),
  })

  const changeOffer = (offer: OfferItemType) => {
    updateCachedApplication({
      selected_offer_term: offer.term_length,
    })
  }

  useEffect(
    function generateOffersForSelectedLoanAmount() {
      const offers = generateOffers(selectedLoanAmount, apr)
      setOfferList(offers)
    },
    [cachedApplication.selected_loan_amount],
  )

  useEffect(
    function resetOffer() {
      if (!offerList.length || cachedApplication.selected_offer_term) {
        return
      }
      changeOffer(offerList[0])
    },
    [offerList],
  )

  const setSelectedOfferIdOnClick = (offer: OfferItemType) => {
    changeOffer(offer)
    selectOfferChanged(offer.term_length.toString())
  }

  return {
    apr,
    borrower,
    offerList,
    selectedOfferTerm,
    setSelectedOfferIdOnClick,
    currencyFormat,
    percentFormat,
    isQCResident,
    saveOfferTermMutation,
  }
}
