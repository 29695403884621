import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { PaymentContainerType } from 'src/components/ReviewOrder/types'
import { useOriginationContext } from 'src/hooks'
import theme from 'src/themes'
import { StepsEnum } from 'src/types'
import { changePaymentMethodClicked } from 'src/utils'

import { ChangeLink, RightBox, TableDivider } from '../styles'

export function PaymentContainer({ paymentMethod, changeLink }: PaymentContainerType) {
  const { setStep } = useOriginationContext()
  const { t } = useTranslation()
  const changeLabel = t('ReviewOrder.change')
  return (
    <Grid container marginTop="16px">
      <Grid item xs={12} marginBottom="8px">
        <Typography variant="label" marginBottom="0px">
          {t('ReviewOrder.paymentMethod.title')}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography variant="body2" color={theme.color.grey6}>
          {paymentMethod}
        </Typography>
      </Grid>
      <Grid item xs={3} alignItems="center">
        <RightBox>
          <ChangeLink
            onClick={() => {
              if (changeLink) {
                setStep(changeLink)
                changePaymentMethodClicked(changeLabel, StepsEnum.CREDIT_RENEWAL_REVIEW)
              } else {
                setStep(StepsEnum.PAYMENT_METHOD_SELECT)
                changePaymentMethodClicked(changeLabel, StepsEnum.ORDER_REVIEW)
              }
            }}
          >
            {t('ReviewOrder.change')}
          </ChangeLink>
        </RightBox>
      </Grid>
      <Grid item xs={12}>
        <TableDivider style={{ marginBottom: 0 }} />
      </Grid>
    </Grid>
  )
}
