import { Grid, Link, Typography, Box, Slider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'

import { APRGuarantee } from 'src/components/CreditRenewal/styles'
import { useOriginationContext } from 'src/hooks'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { isQCResident } from 'src/utils/borrower'
import theme from 'src/themes'
import { questionMarkCR } from 'src/images'
import { LOAN_AMOUNT_STEP } from 'src/utils/constants'
import { HandleLoanAmountChange } from 'src/utils/loanAmount'

export function LoanAmountComponent() {
  const { bootstrapInfo, maxLoanAmount, minLoanAmount, cachedApplication } = useOriginationContext()

  const { t } = useTranslation()
  const { currencyFormat, percentFormat } = useLocalizedFormatters()
  const apr = bootstrapInfo?.application?.apr as number
  const selectedLoanAmount = cachedApplication.selected_loan_amount || maxLoanAmount

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Typography variant="h1" textAlign="center">
          {t('CreditRenewals.LoanDetails.title')}
        </Typography>
      </Grid>
      <Grid item xs={12} marginTop="32px" marginBottom="4px">
        <Typography
          data-testid="loanAmount"
          variant="h2"
          color={theme.color.grey9}
          textAlign="center"
          fontSize="24px"
        >
          {currencyFormat(selectedLoanAmount, 0)}
        </Typography>
        <Box marginTop="12px" paddingLeft="0px" paddingRight="0px">
          <Slider
            data-testid="loanAmountSlider"
            value={selectedLoanAmount}
            onChange={HandleLoanAmountChange}
            aria-label="Default"
            step={LOAN_AMOUNT_STEP}
            min={minLoanAmount}
            max={maxLoanAmount}
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" textAlign="left" data-testid="minLoanAmt">
          {currencyFormat(minLoanAmount, 0)}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" textAlign="right" data-testid="maxLoanAmt">
          {currencyFormat(maxLoanAmount, 0)}
        </Typography>
      </Grid>
      <APRGuarantee container>
        <Typography variant="body2" textAlign="center">
          {t(
            isQCResident()
              ? 'LoanAmount.guaranteedAprLabelQC'
              : 'CreditRenewals.LoanDetails.selectorTitle',
            {
              apr: percentFormat(apr),
              loan_amt: currencyFormat(selectedLoanAmount, 0),
              total_amt: currencyFormat(selectedLoanAmount, 0),
            },
          )}
          <img
            src={questionMarkCR}
            alt="tooltip"
            width="20px"
            height="20px"
            data-tooltip-id="loanDetailsLearnMore"
          />
        </Typography>

        <Tooltip
          id="loanDetailsLearnMore"
          place="bottom"
          style={{
            background: 'white',
            color: 'black',
            fontSize: '14px',
            borderRadius: '8px',
            padding: '10px 15px',
            width: '340px',
            height: '144px',
          }}
          clickable
          opacity="1"
        >
          {t('CreditRenewals.LoanDetails.tooltipContent')}
          <Link
            sx={{
              display: 'block',
              marginTop: 1,
              fontSize: 14,
            }}
            variant="linkMedium"
            //TODO: replace URL
            href="googl.ca"
            target="_blank"
          >
            {/* TODO:fix for loandetails */}
            {t('BankConnectCarousel.why.learnMore')}
          </Link>
        </Tooltip>
      </APRGuarantee>
    </Grid>
  )
}
