import { Grid, List, Radio, Typography, Box, Fade } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { NoEarlyRepayFeesMessage } from 'src/components/common/NoEarlyRepayFeesMessage'
import { SelectOfferListItem, SelectOfferListItemButton } from 'src/components/CreditRenewal/styles'
import { useSelectOffer } from 'src/components/CreditRenewal/hooks/useSelectOffer'

export function SelectOfferComponent() {
  const { t } = useTranslation()

  const {
    apr,
    borrower,
    offerList,
    selectedOfferTerm,
    setSelectedOfferIdOnClick,
    currencyFormat,
    percentFormat,
    isQCResident,
  } = useSelectOffer()

  const offerItems = offerList.map(offer => (
    <List key={offer.term_length}>
      <SelectOfferListItem>
        <SelectOfferListItemButton
          data-testid="offer"
          selected={offer.term_length === selectedOfferTerm}
          onClick={() => setSelectedOfferIdOnClick(offer)}
          disableRipple
        >
          <Radio checked={offer.term_length === selectedOfferTerm} />
          <Box>
            <Typography variant="label">
              {t('SelectOffer.offerAmount', {
                offerAmount: currencyFormat(offer.payment_amount, 2),
              })}
            </Typography>
            <Typography variant="body2">
              {t(isQCResident(borrower) ? 'SelectOffer.aprQC' : 'SelectOffer.apr', {
                apr: percentFormat(apr),
              })}
            </Typography>
          </Box>
          <Box flexGrow={1}>
            <Typography variant="body2" textAlign="right">
              {t('SelectOffer.termLength', { termLength: offer.term_length })}
            </Typography>
          </Box>
        </SelectOfferListItemButton>
      </SelectOfferListItem>
    </List>
  ))

  return (
    <Grid sx={{ paddingTop: '32px' }}>
      <Grid>
        <Typography variant="h1" textAlign="center">
          {t('CreditRenewals.LoanDetails.offerTitle')}
        </Typography>
      </Grid>
      <Fade>
        <>
          <Grid item xs={12} marginTop="32px">
            {offerItems}
          </Grid>
          <Grid item xs={12} marginTop="8px">
            <NoEarlyRepayFeesMessage />
          </Grid>
        </>
      </Fade>
    </Grid>
  )
}
