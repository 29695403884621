import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import { Box, CircularProgress, Grid, Link, Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useEffect, useRef } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import {
  PADHeaderContainer,
  PADHeadingText,
  PADScheduleContainer,
  PADScheduleHeader,
} from 'src/components/PaymentSchedule/PadSchedule'
import { useOriginationContext } from 'src/hooks'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { useScheduleHelper } from 'src/hooks/useScheduleHelper'
import theme from 'src/themes'
import { MonthlyScheduleEnum, PaymentScheduleEnum, StepsEnum } from 'src/types'
import { confirmScheduleChangePaymentScheduleClicked } from 'src/utils'
import PaymentProtectionInfo from 'src/components/PaymentSchedule/shared/PaymentProtectionInfo'

const CalendarLoader = () => (
  <Grid item xs={12} marginBottom="16px" marginTop="16px">
    <Box style={{ display: 'flex', margin: 'auto' }}>
      <CircularProgress sx={{ margin: 'auto', display: 'block', border: 'none' }} color="primary" />
    </Box>
  </Grid>
)

interface ChangeScheduleButtonProps {
  nextStep?: StepsEnum
}

const ChangeScheduleButton = ({ nextStep }: ChangeScheduleButtonProps) => {
  const { setStep } = useOriginationContext()
  const { t } = useTranslation()

  const changeScheduleLabel = t('PaymentSchedule.PaymentScheduleCalendar.changeScheduleLabel')

  return (
    <Grid item xs={12} marginTop="16px">
      <Link
        display="block"
        textAlign="center"
        variant="body2"
        onClick={() => {
          confirmScheduleChangePaymentScheduleClicked(
            changeScheduleLabel,
            StepsEnum.REPAYMENT_SCHEDULE_CONFIRM,
          )

          // If nextStep is defined (credit renewals loan details)
          if (nextStep) {
            setStep(nextStep)
            return
          }

          // Original flow
          setStep(StepsEnum.REPAYMENT_SCHEDULE)
        }}
      >
        {changeScheduleLabel}
      </Link>
    </Grid>
  )
}

const Disclaimer = () => {
  const { t } = useTranslation()
  return (
    <Typography
      textAlign="center"
      variant="body2"
      marginTop="16px"
      marginBottom="32px"
      paddingX="4px"
      color={theme.color.grey6}
    >
      {t('PaymentSchedule.PaymentScheduleCalendar.disclaimer')}
    </Typography>
  )
}

export function PaymentScheduleCalendar({
  isLoading,
  showChangeButton,
  showDisclaimer,
  first_payment_date,
  payment_cycle_due_date,
  schedule,
  monthly_choice,
  withdraw_amount,
  nextStep,
}: {
  isLoading: boolean
  showChangeButton: boolean
  showDisclaimer: boolean
  first_payment_date?: string
  payment_cycle_due_date?: string
  schedule?: string
  monthly_choice?: string
  withdraw_amount?: string
  nextStep?: StepsEnum
}) {
  const { t } = useTranslation()
  const { getPaymentScheduleText } = useScheduleHelper()
  const { dateFormatCustom, currencyFormatFromStr } = useLocalizedFormatters()

  const containerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const container = containerRef.current
    if (container) {
      container.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isLoading])

  const calendarContent = () => {
    if (isLoading) {
      return <CalendarLoader />
    }

    const displayWithdrawAmount = currencyFormatFromStr(withdraw_amount)
    const paymentScheduleText = getPaymentScheduleText({
      frequency: schedule! as PaymentScheduleEnum,
      monthlyChoice: monthly_choice! as MonthlyScheduleEnum,
      paymentCycleDueDate: payment_cycle_due_date ? parseISO(payment_cycle_due_date!) : null,
    })

    const displayDate = first_payment_date
      ? dateFormatCustom(
          parseISO(first_payment_date),
          t('PaymentSchedule.PaymentScheduleCalendar.firstPaymentDateFormat'),
        )
      : ''

    return (
      <Grid container padding="16px">
        <Grid item xs={12}>
          <Typography
            data-testid="withdrawalMessage"
            textAlign="center"
            variant="h3"
            color={theme.color.grey9}
          >
            <Trans
              i18nKey="PaymentSchedule.PaymentScheduleCalendar.withdrawalText"
              values={{
                displayWithdrawAmount,
                paymentScheduleText,
              }}
            >
              We will withdraw <PaymentProtectionInfo amount={displayWithdrawAmount} />
              {paymentScheduleText}
            </Trans>
          </Typography>
          {displayDate && (
            <Typography
              textAlign="center"
              variant="body2"
              marginTop="4px"
              color={theme.color.grey6}
            >
              {t('PaymentSchedule.PaymentScheduleCalendar.startText', { displayDate })}
            </Typography>
          )}
        </Grid>
        {showChangeButton && <ChangeScheduleButton nextStep={nextStep} />}
      </Grid>
    )
  }

  return (
    <Grid container marginBottom="16px" ref={containerRef}>
      <PADScheduleHeader item xs={12}>
        <PADHeaderContainer>
          <CalendarMonthOutlinedIcon sx={{ color: theme.color.grey9 }} />
          <PADHeadingText>{t('PaymentSchedule.PaymentScheduleCalendar.title')}</PADHeadingText>
        </PADHeaderContainer>
      </PADScheduleHeader>
      <PADScheduleContainer>{calendarContent()}</PADScheduleContainer>
      {showDisclaimer && <Disclaimer />}
    </Grid>
  )
}
