import { Box, GlobalStyles, Grid, Typography, styled } from '@mui/material'
import { TFunction } from 'i18next'
import { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { PrimaryCTA } from 'src/components/common/Buttons'
import { DivRoot } from 'src/components/common/DivRoot'
import RootLayout from 'src/components/common/RootLayout'
import { StyledDivider } from 'src/components/common/StyledDivider'
import { PrequalificationContext } from 'src/contexts'
import { useConfiguration } from 'src/hooks'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { logoImgSvg } from 'src/images'
import theme from 'src/themes'
import {
  FeatureFlags,
  GetPartnerConfigResponseType,
  HeaderType,
  IconType,
  PartnersEnum,
  StepsEnum,
} from 'src/types'
import DisclaimerCross from 'src/components/common/DisclaimerCross'

const ConsentTypography = styled(Typography)({
  fontWeight: 400,
  color: theme.color.grey6,
  fontSize: '12px',
  lineHeight: '18px',
})

export const LandingTemplateDivider = () => (
  <Grid item xs={12}>
    <StyledDivider sx={{ margin: '10px 0', borderColor: theme.color.grey2 }} />
  </Grid>
)

/**
 * Landing page template that takes in a header and a body, pre-populating the wrapping styles, CTA, and consents.
 */
export function LandingTemplate({
  headerConfig,
  iconConfig,
  body,
}: {
  headerConfig?: HeaderType
  iconConfig?: IconType
  body: JSX.Element
}) {
  const { t } = useTranslation()
  const { percentFormat, currencyFormat } = useLocalizedFormatters()
  const { setStepE2E, partnerConfig } = useContext(PrequalificationContext)
  const { loanLimits } = useConfiguration()
  const { isFigLandingPromo } = useFlags<FeatureFlags>()
  const handleClick = () => {
    setStepE2E(StepsEnum.CONSENTS)
  }

  /**
   * Retrieves the disclaimer text based on the partner name.
   * Used for separating the disclaimer text for different partners.
   * @param partnerName - The name of the partner.
   * @param t - The translation function.
   * @param disclaimerValues - The values used in the disclaimer text.
   * @returns The disclaimer text for the specified partner.
   */
  const getDisclaimerText = (
    partnerConfig: GetPartnerConfigResponseType,
    t: TFunction<'translation', undefined>,
    disclaimerValues: { min_apr: string; max_apr: string; min_amt: string; max_amt: string },
  ) => {
    const partnerName = partnerConfig?.name
    const { min_apr, max_apr, min_amt, max_amt } = disclaimerValues

    if (partnerConfig?.is_referral) {
      return t('e2e.Landing.figFinancialReferral.disclaimer', disclaimerValues)
        .split('\n')
        .map((line: string) => line)
    }
    if (isFigLandingPromo) {
      return t('e2e.Landing.FigPromo.disclaimer', disclaimerValues)
        .split('\n')
        .map((line: string) => line)
    }
    switch (partnerName) {
      case PartnersEnum.LFLGROUP:
        return t('e2e.Landing.LFLGroup.disclaimer', disclaimerValues)
          .split('\n')
          .map((line: string) => line)

      case PartnersEnum.PERKOPOLIS:
        return t('e2e.Landing.perkopolis.disclaimer', disclaimerValues)
          .split('\n')
          .map((line: string) => line)
      case PartnersEnum.CREDIT_KARMA_PROMO:
        return (
          <>
            <Trans
              i18nKey="e2e.Landing.creditKarmaPromo.disclaimer_part1"
              values={{
                min_apr,
                max_apr,
                min_amt,
                max_amt,
              }}
            >
              *Based on average processing times. You will only receive an offer if you meet Fig’s
              current credit standards. Any offer received is conditional to Fig’s standard review
              which may include, amongst others, your identity, the verification of information
              you’ve provided in your application and your ability to pay. Fig loans range from
              {{ min_amt }} to {{ max_amt }} with terms from 24 to 60 months. APRs range from{' '}
              {{ min_apr }} to {{ max_apr }}
              and will depend on assessment of your credit profile. Offer excludes residents of
              Northwest Territories, Nunavut and Yukon.**You do not need to obtain a loan in order
              to qualify. If you do decide to obtain a loan, don’t take on more debt than what you
              need and don’t borrow more than you can afford. Read all of the terms and conditions
              of your loan offer and make sure you understand them before accepting.
            </Trans>
            <Trans
              i18nKey="e2e.Landing.creditKarmaPromo.disclaimer_part2"
              components={{
                disclaimerCross: <DisclaimerCross />,
                emailLink: (
                  <a target="_blank" href="mailto: contests@fig.ca" rel="noreferrer">
                    contests@fig.ca.
                  </a>
                ),
              }}
            ></Trans>
            <Trans i18nKey="e2e.Landing.creditKarmaPromo.disclaimer_part3">
              Limit of one entry per member. To be eligible you must be a Credit Karma member, a
              resident of Canada and be over the age of majority in your province of residence.
              Contest excludes residents of Northwest Territories, Nunavut and Yukon.
              <a
                target="_blank"
                href={t('e2e.Landing.creditKarmaPromo.disclaimerURL')}
                rel="noreferrer"
              >
                See full contest rules and regulations.
              </a>
            </Trans>
          </>
        )
      case PartnersEnum.CREDIT_KARMA:
        return t('e2e.Landing.creditKarma.disclaimer', disclaimerValues)
      case PartnersEnum.THE_BRICK:
        return t('e2e.Landing.theBrick.disclaimer', disclaimerValues)
          .split('\n')
          .map((line: string) => line)
      case PartnersEnum.NEO:
        return t('e2e.Landing.neo.disclaimer', disclaimerValues)
          .split('\n')
          .map((line: string) => line)
      case PartnersEnum.FAIRSTONE:
        return t('e2e.Landing.fairstone.disclaimer', disclaimerValues)
          .split('\n')
          .map((line: string) => line)
      default:
        return t('e2e.Landing.disclaimer', disclaimerValues)
    }
  }

  const DisclaimerSection = ({
    partnerConfig,
  }: {
    partnerConfig: GetPartnerConfigResponseType
  }) => {
    const { t } = useTranslation()
    const disclaimerValues = {
      min_apr: percentFormat(loanLimits?.minApr, 2),
      max_apr: percentFormat(loanLimits?.maxApr, 2),
      min_amt: currencyFormat(loanLimits?.minLoanAmount, 0),
      max_amt: currencyFormat(loanLimits?.maxLoanAmount, 0),
    }

    const disclaimerText = getDisclaimerText(partnerConfig, t, disclaimerValues)

    return (
      <Grid item xs={12}>
        <ConsentTypography>
          {Array.isArray(disclaimerText)
            ? disclaimerText.map(line => (
                <>
                  {line}
                  <br />
                </>
              ))
            : disclaimerText}
        </ConsentTypography>
      </Grid>
    )
  }

  return (
    <>
      <GlobalStyles
        styles={{
          [theme.breakpoints.down('md')]: {
            '.fc-widget-normal': { bottom: '75px !important' },
          },
        }}
      />
      <RootLayout
        logo={{ src: logoImgSvg, alt: 'Fig logo' }}
        header={headerConfig}
        iconConfig={iconConfig}
        showProgressBar={false}
        contentStyles={{
          [theme.breakpoints.up('md')]: { marginTop: '48px' },
          [theme.breakpoints.down('md')]: { marginBottom: '96px' },
        }}
      >
        <DivRoot
          sx={{
            [theme.breakpoints.up('md')]: { paddingTop: '48px', paddingBottom: '48px' },
            [theme.breakpoints.down('md')]: { padding: '16px 16px 48px 16px' },
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Grid
              container
              style={{
                textAlign: 'center',
              }}
              direction="column"
              spacing={'16px'}
            >
              <Grid
                item
                sx={{
                  [theme.breakpoints.up('md')]: { display: 'none' },
                }}
              >
                <LandingTemplateDivider />
              </Grid>
              {body}
              <Grid
                item
                xs={12}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    position: 'fixed',
                    bottom: '0',
                    background: theme.color.white,
                    padding: '16px',
                    width: '100%',
                  },
                }}
              >
                {partnerConfig?.name === PartnersEnum.CREDIT_KARMA_PROMO ? (
                  <PrimaryCTA
                    buttonText={t('LandingPromoBanner.creditKarmaPromo.getQuoteLabel')}
                    onClick={handleClick}
                  />
                ) : (
                  <PrimaryCTA buttonText={t('e2e.Landing.buttonLabel')} onClick={handleClick} />
                )}
              </Grid>
              <DisclaimerSection partnerConfig={partnerConfig!} />
            </Grid>
          </Box>
        </DivRoot>
      </RootLayout>
    </>
  )
}
