import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties
    label: React.CSSProperties
    large: React.CSSProperties
    linkMedium: React.CSSProperties
    linkLarge: React.CSSProperties
    title: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties
    label?: React.CSSProperties
    large?: React.CSSProperties
    linkMedium?: React.CSSProperties
    linkLarge?: React.CSSProperties
    text?: React.CSSProperties
    title?: React.CSSProperties
    description?: React.CSSProperties
    body4?: React.CSSProperties
  }

  interface ButtonPropsVariantOverrides {
    textGrey?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true
    description: true
    body4: true
    body3: true
    large: true
    label: true
    linkMedium: true
    linkLarge: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    textGrey: true
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    color: {
      white: React.CSSProperties['color']
      black: React.CSSProperties['color']
      grey1: React.CSSProperties['color']
      grey2: React.CSSProperties['color']
      grey3: React.CSSProperties['color']
      grey4: React.CSSProperties['color']
      grey5: React.CSSProperties['color']
      grey6: React.CSSProperties['color']
      grey7: React.CSSProperties['color']
      grey8: React.CSSProperties['color']
      grey9: React.CSSProperties['color']
      grey10: React.CSSProperties['color']
      border: React.CSSProperties['color']
      brand1: React.CSSProperties['color'] // ROYALE
      brand2: React.CSSProperties['color'] // MERMAID
      brand3: React.CSSProperties['color'] // FOGGY SKY
      brand4: React.CSSProperties['color'] // BLUE WINTER
      brand5: React.CSSProperties['color'] // SPEAR MINT
      brand6: React.CSSProperties['color'] // HONEYDEW
      brand7: React.CSSProperties['color'] // BEIGE
      brand8: React.CSSProperties['color'] // DARK FIG GREEN
      error: React.CSSProperties['color'] // BERRY
      success1: React.CSSProperties['color'] // SPRINGLEAF
      success2: React.CSSProperties['color'] // LIME
      warning1: React.CSSProperties['color'] // SUNSET
      warning2: React.CSSProperties['color'] // LEMON
      warning3: React.CSSProperties['color'] // WARNING ORANGE
    }
    width: {
      buttonPrimarySm: React.CSSProperties['width']
      buttonPrimaryLg: React.CSSProperties['width']
      buttonPrimaryFull: React.CSSProperties['width']
    }
  }

  interface ThemeOptions {
    color: {
      white: React.CSSProperties['color']
      black: React.CSSProperties['color']
      grey1: React.CSSProperties['color']
      grey2: React.CSSProperties['color']
      grey3: React.CSSProperties['color']
      grey4: React.CSSProperties['color']
      grey5: React.CSSProperties['color']
      grey6: React.CSSProperties['color']
      grey7: React.CSSProperties['color']
      grey8: React.CSSProperties['color']
      grey9: React.CSSProperties['color']
      grey10: React.CSSProperties['color']
      border: React.CSSProperties['color']
      brand1: React.CSSProperties['color'] // ROYALE
      brand2: React.CSSProperties['color'] // MERMAID
      brand3: React.CSSProperties['color'] // FOGGY SKY
      brand4: React.CSSProperties['color'] // BLUE WINTER
      brand5: React.CSSProperties['color'] // SPEAR MINT
      brand6: React.CSSProperties['color'] // HONEYDEW
      brand7: React.CSSProperties['color'] // BEIGE
      error: React.CSSProperties['color'] // BERRY
      success1: React.CSSProperties['color'] // SPRINGLEAF
      success2: React.CSSProperties['color'] // LIME
      warning1: React.CSSProperties['color'] // SUNSET
      warning2: React.CSSProperties['color'] // LEMON
      warning3: React.CSSProperties['color'] // WARNING ORANGE
    }
    width: {
      buttonPrimarySm: React.CSSProperties['width']
      buttonPrimaryLg: React.CSSProperties['width']
      buttonPrimaryFull: React.CSSProperties['width']
    }
  }
}

export const figColors = {
  white: '#FFFFFF',
  black: '#000000',
  grey1: '#f9fafb',
  grey2: '#f3f4f6',
  grey3: '#e5e7eb',
  grey4: '#d1d5db',
  grey5: '#9ca3af',
  grey6: '#6b7280',
  grey7: '#4b5563',
  grey8: '#374151',
  grey9: '#1f2937',
  grey10: '#111827',
  border: '#F3F4F6',
  brand1: '#005151', // FIG GREEN
  brand2: '#CFFAFE', // MERMAID
  brand3: '#FAFAFA', // FOGGY SKY
  brand4: '#F5FEFF', // BLUE WINTER
  brand5: '#ECFBF7', // SPEAR MINT
  brand6: '#F7FDE9', // HONEYDEW
  brand7: '#F6F5F3', // BEIGE
  brand8: '#003838', // DARK FIG GREEN
  error: '#B50000', // BERRY
  success1: '#84CC16', // SPRINGLEAF
  success2: '#ECFCCB', // LIME
  warning1: '#754900',
  warning2: '#FEF3C7', // LEMON
  warning3: '#FFF4E2', // WARNING ORANGE
}

const theme = createTheme({
  color: {
    ...figColors,
  },
  width: {
    buttonPrimarySm: '260px', // '358px', // TO UNDO
    buttonPrimaryLg: '358px',
    buttonPrimaryFull: '100%',
  },
  palette: {
    primary: {
      main: figColors.brand1,
    },
    error: {
      main: figColors.error,
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    body1: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '22px',
      color: figColors.grey6,
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      color: figColors.grey6,
    },
    body3: {
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '18px',
      color: figColors.grey9,
    },
    body4: {
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: figColors.grey7,
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '32px',
      lineHeight: '48px',
      color: figColors.black,
    },
    description: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '20px',
      color: figColors.black,
    },

    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      color: figColors.grey6,
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '20px',
      linespace: '12px',
      color: figColors.grey8,
    },
    h1: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '28px',
      color: figColors.grey9,
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '30px',
      color: figColors.grey9,
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '24px',
      color: figColors.grey9,
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      color: figColors.grey9,
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      linespace: '0px',
      color: figColors.grey7,
    },
    label: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
      color: figColors.grey9,
      // for some reason this is needed. Even though it should be inherited by root.
      fontFamily: "'Poppins', sans-serif",
    },
    linkMedium: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '20px',
      color: figColors.brand1,
    },
    linkLarge: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '22px',
      color: figColors.brand1,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }
      `,
    },
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'inherit',
          minWidth: 'auto',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '20px',
          fontStyle: 'normal',
          width: '100%',
          borderRadius: '100px',
          padding: '12px',
          height: '46px',
          backgroundColor: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:disabled': {
            cursor: 'not-allowed',
          },
          '&.MuiButton-contained:disabled': {
            backgroundColor: figColors.grey2,
            color: figColors.grey6,
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: `1.5px solid ${figColors.brand1}`,
            '&:hover': {
              backgroundColor: figColors.brand5,
            },
            '&.Mui-disabled': {
              color: figColors.grey6,
              border: `1px solid ${figColors.grey4}`,
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            borderRadius: '3px',
            padding: '4px',
            width: 'auto',
            height: '32px',
            lineHeight: '22px',
            '&:hover': {
              borderRadius: '3px',
              backgroundColor: figColors.brand5,
            },
          },
        },
        {
          props: { variant: 'textGrey' },
          style: {
            color: figColors.grey6,
            borderRadius: '3px',
            padding: '4px',
            width: 'auto',
            height: '32px',
            lineHeight: '22px',
            '&:hover': {
              borderRadius: '3px',
              backgroundColor: figColors.grey2,
            },
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          fontStyle: 'normal',
          textDecoration: 'none',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '20px',
          color: figColors.brand1,
        },
      },
      variants: [
        {
          props: { variant: 'large' },
          style: {
            fontStyle: 'normal',
            textDecoration: 'none',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '20px',
            color: figColors.brand1,
          },
        },
      ],
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
  },
})

export default theme
