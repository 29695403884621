import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ApplyNowButton } from 'src/components/EndToEnd/landing/Walmart/components/ApplyNowButton'
import { SectionContainer } from 'src/components/EndToEnd/landing/Walmart/components/SectionContainer'
import { BannerImage, ImgStyle } from 'src/components/EndToEnd/landing/Walmart/styles'
import { ApplyNowButtonSizes } from 'src/components/EndToEnd/landing/Walmart/types'
import { offerSectionBanner, offerSectionLogos } from 'src/images'

export const OfferSection = () => {
  const { t } = useTranslation()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  return (
    <SectionContainer
      style={{
        borderRadius: '2%',
        border: '1px solid',
        borderColor: theme.palette.secondary.light,
        boxShadow: '0px 4px 8px 0px #00000014',
      }}
    >
      <Grid item sm={12} md={4} sx={{ order: { xs: 2, md: 1 }, padding: '20px' }}>
        <Typography variant="sectionHeading">
          {t('e2e.Landing.walmart.offerSection.title')}
        </Typography>
        <Typography variant="body">{t('e2e.Landing.walmart.offerSection.content')}</Typography>
        <ApplyNowButton size={ApplyNowButtonSizes.SMALL} />
        <ImgStyle
          src={offerSectionLogos}
          style={isMobile ? { marginTop: '5%' } : { marginTop: '30%' }}
          sx={isTablet ? { maxWidth: '70%' } : { maxWidth: '100%%' }}
        />
      </Grid>
      <Grid item sm={12} md={8} sx={{ order: { xs: 1, md: 2 } }}>
        <BannerImage
          src={offerSectionBanner}
          style={
            isMobile
              ? { borderTopLeftRadius: '2%', borderTopRightRadius: '2%' }
              : { borderTopRightRadius: '2%', borderBottomRightRadius: '2%' }
          }
        />
      </Grid>
    </SectionContainer>
  )
}
