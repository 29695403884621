import { styled } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useOriginationContext } from 'src/hooks'
import { CreditRenewalProgressEnum, FeatureFlags, ProgressEnum, StepsEnum } from 'src/types'
import { getAnalyticsCallback, parseUrl } from 'src/utils'

const ProgressStepsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '6px',
  width: '100%',
  height: '45px',
  [theme.breakpoints.up('md')]: {
    '&:hover .stepHint': {
      opacity: 1,
    },
  },
}))

const ProgressStep = styled('div')(({ theme }) => ({
  flex: '1 1 0',
  width: 0,
  '&[data-step-todo="false"]': {
    cursor: 'pointer',
    '& .stepTab': {
      backgroundColor: theme.color.brand1,
    },
    '& .stepHint': {
      color: theme.color.brand1,
    },
  },
}))

const StepTab = styled('div')(({ theme }) => ({
  height: '6px',
  backgroundColor: theme.color.grey3,
}))

const ProgressStepHint = styled('div')(({ theme }) => ({
  paddingTop: '16px',
  fontSize: '16px',
  width: '100%',
  textAlign: 'center',
  lineHeight: '16px',
  fontWeight: 400,
  color: theme.color.grey6,
  opacity: 0,
  transition: 'opacity 1s',
  userSelect: 'none',
}))

const stepToProgressEnum: { [key in StepsEnum]: ProgressEnum | CreditRenewalProgressEnum | null } =
  {
    [StepsEnum.END_TO_END]: null,
    [StepsEnum.PREQUALIFICATION]: null,
    [StepsEnum.LANDING]: null,
    [StepsEnum.OFFER]: null,
    [StepsEnum.SECOND_LOOK_OFFER]: null,
    [StepsEnum.CONSENTS]: null,
    [StepsEnum.QC_CONSENTS]: ProgressEnum.LOAN_AMOUNT,
    [StepsEnum.BANK_AUTO_CONNECT]: ProgressEnum.LINK_BANK_ACCOUNT,
    [StepsEnum.BANK_CONNECT_ERROR]: null,
    [StepsEnum.BANK_SUCCESS]: ProgressEnum.LINK_BANK_ACCOUNT,
    [StepsEnum.DECLINED]: null,
    [StepsEnum.ERROR]: null,
    [StepsEnum.EXPIRED]: null,
    [StepsEnum.FLINKS_PAYMENT_CHECK]: ProgressEnum.LINK_BANK_ACCOUNT,
    [StepsEnum.KYC_PERMISSIONS_ONFIDO_ERROR]: null,
    [StepsEnum.KYC_ID_FAIL]: null,
    [StepsEnum.KYC_RETRY_GENERIC]: null,
    [StepsEnum.KYC_PII_RETRY]: null,
    [StepsEnum.KYC_LOADING]: null,
    [StepsEnum.KYC_FLINKS_FAIL]: null,
    [StepsEnum.KYC_FLINKS_INVALID_BANK_ACCOUNT]: null,
    [StepsEnum.KYC_SUCCESS]: ProgressEnum.ID_VERIFICATION,
    [StepsEnum.KYC_TOS_DECLINED]: null,
    [StepsEnum.KYC_VERIFY]: ProgressEnum.ID_VERIFICATION,
    [StepsEnum.LOAN_AMOUNT]: ProgressEnum.LOAN_AMOUNT,
    [StepsEnum.LOAN_PURPOSE]: ProgressEnum.LOAN_PURPOSE,
    [StepsEnum.NO_APP_ID]: null,
    [StepsEnum.OCCUPATION]: ProgressEnum.OCCUPATION,
    [StepsEnum.ORDER_DECLINED]: null,
    [StepsEnum.ORDER_FINISH]: ProgressEnum.REVIEW,
    [StepsEnum.ORDER_REVIEW]: ProgressEnum.REVIEW,
    [StepsEnum.OTP]: ProgressEnum.OTP,
    [StepsEnum.PHONE]: ProgressEnum.PHONE,
    [StepsEnum.PAGE_NOT_FOUND]: null,
    [StepsEnum.PAYMENT_METHOD_SELECT]: ProgressEnum.LINK_BANK_ACCOUNT,
    [StepsEnum.REPAYMENT_SCHEDULE]: ProgressEnum.REVIEW,
    [StepsEnum.REPAYMENT_SCHEDULE_CONFIRM]: ProgressEnum.REVIEW,
    [StepsEnum.PAYMENT_PROTECTION]: ProgressEnum.REVIEW,
    [StepsEnum.SELECT_OFFER]: ProgressEnum.PAYMENT_PLAN,
    [StepsEnum.SETTLED]: null,
    [StepsEnum.KYC_IMAGE_QUALITY_RETRY]: null,
    [StepsEnum.CREDIT_RENEWAL_ELIGIBILITY]: CreditRenewalProgressEnum.ELIGIBILITY,
    [StepsEnum.CREDIT_RENEWAL_DECLINED]: null,
    [StepsEnum.CREDIT_RENEWAL_CONFIRM_INFORMATION]: CreditRenewalProgressEnum.CONFIRM_INFORMATION,
    [StepsEnum.CREDIT_RENEWAL_LOAN_DETAILS]: CreditRenewalProgressEnum.LOAN_DETAILS,
    [StepsEnum.CREDIT_RENEWAL_REPAYMENT_SCHEDULE]: CreditRenewalProgressEnum.REPAYMENT_SCHEDULE,
    [StepsEnum.CREDIT_RENEWAL_PAYMENT_PROTECTION]: CreditRenewalProgressEnum.PAYMENT_PROTECTION,
    [StepsEnum.CREDIT_RENEWAL_REVIEW]: CreditRenewalProgressEnum.REVIEW,
    [StepsEnum.CREDIT_RENEWAL_FINISH]: null,
  }

/**
 *
 */
export function ProgressBar() {
  const { setStep, bootstrapInfo } = useOriginationContext()
  const [hasPhoneStep, setHasPhoneStep] = useState<boolean | null>(null)
  const { t } = useTranslation()
  const { skipOtp } = useFlags<FeatureFlags>()
  const isBootstrapSet = Object.keys(bootstrapInfo ?? {}).length !== 0
  useEffect(() => {
    if (hasPhoneStep == null && isBootstrapSet) {
      setHasPhoneStep(!bootstrapInfo.borrower?.borrower_mobile)
      // save this as state, because we don't want the number of steps to change
      //     once the phone number step has been completed
    }
  }, [bootstrapInfo])

  if (!isBootstrapSet) {
    return <ProgressStepsContainer />
  }
  const { currentStep } = parseUrl()

  const progressStep: ProgressEnum | CreditRenewalProgressEnum | null =
    stepToProgressEnum[currentStep as StepsEnum]

  const ORIGINATION_STEPS: ProgressEnum[] = [
    ProgressEnum.LOAN_AMOUNT,
    ProgressEnum.PAYMENT_PLAN,
    ...(hasPhoneStep ? (skipOtp ? [ProgressEnum.PHONE] : [ProgressEnum.OTP]) : []),
    ProgressEnum.OCCUPATION,
    ProgressEnum.LOAN_PURPOSE,
    ProgressEnum.ID_VERIFICATION,
    ProgressEnum.LINK_BANK_ACCOUNT,
    ProgressEnum.REVIEW,
  ]

  const CREDIT_RENEWAL_STEPS: CreditRenewalProgressEnum[] = [
    CreditRenewalProgressEnum.ELIGIBILITY,
    CreditRenewalProgressEnum.CONFIRM_INFORMATION,
    CreditRenewalProgressEnum.LOAN_DETAILS,
    CreditRenewalProgressEnum.REPAYMENT_SCHEDULE,
    CreditRenewalProgressEnum.PAYMENT_PROTECTION,
    CreditRenewalProgressEnum.REVIEW,
  ]

  const getOrderedStepsByUserFlow = () => {
    if (window.location.href.includes('creditrenewal')) {
      return CREDIT_RENEWAL_STEPS.filter(step => step !== null) as CreditRenewalProgressEnum[]
    }

    return ORIGINATION_STEPS.filter(step => step !== null) as ProgressEnum[]
  }
  const orderedSteps: (ProgressEnum | CreditRenewalProgressEnum)[] = getOrderedStepsByUserFlow()

  const stepIndex = progressStep ? orderedSteps.indexOf(progressStep) : -1

  const progressEnumInfo: {
    [key in ProgressEnum | CreditRenewalProgressEnum]: { displayName: string; step: string }
  } = {
    [ProgressEnum.LOAN_AMOUNT]: {
      displayName: t('ProgressBar.loanAmount'),
      step: StepsEnum.LOAN_AMOUNT,
    },
    [ProgressEnum.OTP]: { displayName: t('ProgressBar.otp'), step: StepsEnum.OTP },
    [ProgressEnum.PHONE]: { displayName: t('ProgressBar.phone'), step: StepsEnum.PHONE },
    [ProgressEnum.PAYMENT_PLAN]: {
      displayName: t('ProgressBar.paymentPlan'),
      step: StepsEnum.SELECT_OFFER,
    },
    [ProgressEnum.LINK_BANK_ACCOUNT]: {
      displayName: t('ProgressBar.linkBankAccount'),
      step: StepsEnum.PAYMENT_METHOD_SELECT,
    },
    [ProgressEnum.ID_VERIFICATION]: {
      displayName: t('ProgressBar.idVerification'),
      step: StepsEnum.KYC_VERIFY,
    },
    [ProgressEnum.OCCUPATION]: {
      displayName: t('ProgressBar.occupation'),
      step: StepsEnum.OCCUPATION,
    },
    [ProgressEnum.LOAN_PURPOSE]: {
      displayName: t('ProgressBar.loanPurpose'),
      step: StepsEnum.LOAN_PURPOSE,
    },
    [ProgressEnum.REVIEW]: { displayName: t('ProgressBar.review'), step: StepsEnum.ORDER_REVIEW },
    [ProgressEnum.PAYMENT_PROTECTION]: {
      displayName: t('ProgressBar.paymentProtection'),
      step: StepsEnum.PAYMENT_PROTECTION,
    },
    [CreditRenewalProgressEnum.ELIGIBILITY]: {
      displayName: t('ProgressBar.eligibility'),
      step: StepsEnum.CREDIT_RENEWAL_ELIGIBILITY,
    },
    [CreditRenewalProgressEnum.CONFIRM_INFORMATION]: {
      displayName: t('ProgressBar.confirmInformation'),
      step: StepsEnum.CREDIT_RENEWAL_CONFIRM_INFORMATION,
    },
    [CreditRenewalProgressEnum.REVIEW]: {
      displayName: t('ProgressBar.review'),
      step: StepsEnum.CREDIT_RENEWAL_REVIEW,
    },
    [CreditRenewalProgressEnum.LOAN_DETAILS]: {
      displayName: t('ProgressBar.loanDetails'),
      step: StepsEnum.CREDIT_RENEWAL_LOAN_DETAILS,
    },
    [CreditRenewalProgressEnum.REPAYMENT_SCHEDULE]: {
      displayName: t('ProgressBar.repaymentSchedule'),
      step: StepsEnum.CREDIT_RENEWAL_REPAYMENT_SCHEDULE,
    },
    [CreditRenewalProgressEnum.PAYMENT_PROTECTION]: {
      displayName: t('ProgressBar.paymentProtectionCR'),
      step: StepsEnum.CREDIT_RENEWAL_PAYMENT_PROTECTION,
    },
  }

  return (
    <ProgressStepsContainer>
      {progressStep &&
        hasPhoneStep !== null &&
        orderedSteps.map((progEnum, i) => {
          const { displayName, step } = progressEnumInfo[progEnum]
          const isTodo = stepIndex < i
          return (
            <ProgressStep
              key={step}
              data-testid="progressStep"
              data-step-todo={isTodo}
              onClick={() => {
                if (!isTodo && i !== stepIndex) {
                  getAnalyticsCallback(`ProgressBar / Navigating to ${displayName}`)(
                    displayName,
                    currentStep as StepsEnum,
                  )
                  setStep(step)
                }
              }}
            >
              <StepTab className="stepTab" />
              <ProgressStepHint className="stepHint">{`${i + 1}. ${displayName}`}</ProgressStepHint>
            </ProgressStep>
          )
        })}
    </ProgressStepsContainer>
  )
}
