import * as Sentry from '@sentry/react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Navigate, Outlet, Route, Routes, useLocation, useParams } from 'react-router-dom'

import { getRetryQueryOptionsWithErrorStep } from 'src/api/api'
import CRPaymentProtection from 'src/components/CreditRenewal/CRPaymentProtection'
import ConfirmInformation from 'src/components/CreditRenewal/ConfirmInformation'
import CreditRenewalDeclined from 'src/components/CreditRenewal/Declined'
import CreditRenewalEligibility from 'src/components/CreditRenewal/Eligibility'
import CreditRenewalFinish from 'src/components/CreditRenewal/Finish'
import Review from 'src/components/CreditRenewal/Review'
import Consents from 'src/components/EndToEnd/Consents'
import { Offer } from 'src/components/EndToEnd/Offer'
import { Prequalification } from 'src/components/EndToEnd/Prequalification'
import { SecondLookOffer } from 'src/components/EndToEnd/SecondLookOffer'
import { Landing } from 'src/components/EndToEnd/landing/Landing'
import ApplicationAlreadySettled from 'src/components/Error/ApplicationAlreadySettled'
import ApplicationDeclined from 'src/components/Error/ApplicationDeclined'
import ApplicationExpired from 'src/components/Error/ApplicationExpired'
import ApplicationIdNotFound from 'src/components/Error/ApplicationIdNotFound'
import { ApplicationOrderDeclined } from 'src/components/Error/ApplicationOrderDeclined'
import BankConnectError from 'src/components/Error/BankConnectError'
import { GenericError } from 'src/components/Error/GenericError'
import MaintenanceMode from 'src/components/Error/MaintenanceMode'
import PageNotFound from 'src/components/Error/PageNotFound'
import Finish from 'src/components/Finish'
import {
  KYCDecisionLoader,
  KYCFlinksFail,
  KYCFlinksInvalidBankAccount,
  KYCOnfidoPermission,
  KYCRetryGeneric,
  KYCSuccess,
  KYCVerify,
  PIIRetry,
} from 'src/components/KYC'
import ImageQualityRetry from 'src/components/KYC/ImageQualityRetry'
import KYCIdFail from 'src/components/KYC/KYCIdFail'
import { LoanAmount } from 'src/components/LoanAmount'
import Occupation from 'src/components/Occupation/Occupation'
import {
  ConnectBankAccount,
  ConnectBankAccountSuccess,
  FlinksPaymentCheck,
  PaymentMethod,
} from 'src/components/PaymentMethod'
import { PaymentProtection } from 'src/components/PaymentProtection'
import ConfirmPaymentSchedule from 'src/components/PaymentSchedule/ConfirmPaymentSchedule'
import EditPaymentSchedule from 'src/components/PaymentSchedule/EditPaymentSchedule'
import PhoneNumber from 'src/components/PhoneNumber/PhoneNumber'
import { PurposeOfLoan } from 'src/components/PurposeOfLoan'
import { QCConsents } from 'src/components/QCConsents/QCConsents'
import ReviewOrder from 'src/components/ReviewOrder/ReviewOrder'
import SelectOffer from 'src/components/SelectOffer/SelectOffer'
import Layout from 'src/components/common/Layout'
import RootLayout from 'src/components/common/RootLayout'
import { UnauthenticatedFreshChatWrapper } from 'src/components/common/UnauthenticatedFreshChatWrapper'
import { LoadingAnimation } from 'src/components/common/animations/Loading'
import OTPContainer from 'src/containers/OTP'
import {
  OriginationProvider,
  PrequalificationContext,
  PrequalificationProvider,
} from 'src/contexts'
import { AutoSaveProvider } from 'src/contexts/prequalification'
import { useConfiguration, useOriginationContext } from 'src/hooks'
import useApiE2E from 'src/hooks/useApiE2E'
import useOrigination from 'src/hooks/useOrigination'
import { logoImgSvg } from 'src/images'
import theme from 'src/themes'
import { ApplicationStatus, FeatureFlags, StepsEnum } from 'src/types'
import { parseUrl } from 'src/utils'
import { SENTRY_TAGS } from 'src/utils/constants'

import LoanDetails from '../CreditRenewal/LoanDetails'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)
function AppRouterOrigination() {
  const {
    logo,
    isApplicationLoanExists,
    bootstrapIsSuccess,
    bootstrapIsLoading,
    loanId,
    bootstrapRefetch,
  } = useOrigination()
  const { bootstrapInfo } = useOriginationContext()
  const { skipOtp } = useFlags<FeatureFlags>()
  const location = useLocation()

  return (
    <SentryRoutes>
      {loanId || isApplicationLoanExists ? (
        <Route
          path=":jwtApiKey"
          element={
            <Layout
              bootstrapIsLoading={bootstrapIsLoading}
              bootstrapIsSuccess={bootstrapIsSuccess}
              logo={logo}
            />
          }
        >
          <Route
            path={StepsEnum.ORDER_FINISH}
            element={<Finish bootstrapRefetch={bootstrapRefetch} />}
          />
          <Route path={StepsEnum.SETTLED} element={<ApplicationAlreadySettled />} />
          <Route path="*" element={<Navigate to={StepsEnum.SETTLED} />} />
        </Route>
      ) : (
        <Route
          path=":jwtApiKey"
          element={
            <Layout
              bootstrapIsLoading={bootstrapIsLoading}
              bootstrapIsSuccess={bootstrapIsSuccess}
              logo={logo}
            />
          }
        >
          <Route index element={<Navigate to={StepsEnum.LOAN_AMOUNT} />} />
          <Route path={StepsEnum.LOAN_AMOUNT} element={<LoanAmount />} />
          <Route
            path={StepsEnum.OTP}
            element={
              <OTPContainer
                nextStepOnSuccess={StepsEnum.OCCUPATION}
                bootstrapRefetch={bootstrapRefetch}
              />
            }
          />
          <Route path={StepsEnum.PHONE} element={<PhoneNumber />} />
          <Route path={StepsEnum.QC_CONSENTS} element={<QCConsents />} />
          <Route
            path={StepsEnum.SELECT_OFFER}
            element={
              <SelectOffer
                nextStep={
                  bootstrapInfo?.borrower?.borrower_mobile
                    ? StepsEnum.OCCUPATION
                    : skipOtp
                      ? StepsEnum.PHONE
                      : StepsEnum.OTP
                }
              />
            }
          />
          <Route path={StepsEnum.PAYMENT_METHOD_SELECT} element={<PaymentMethod />} />
          <Route path={StepsEnum.FLINKS_PAYMENT_CHECK} element={<FlinksPaymentCheck />} />
          <Route path={StepsEnum.BANK_AUTO_CONNECT} element={<ConnectBankAccount />} />
          <Route path={StepsEnum.BANK_SUCCESS} element={<ConnectBankAccountSuccess />} />
          <Route
            path={StepsEnum.ORDER_REVIEW}
            element={
              <ReviewOrder
                nextStep={StepsEnum.ORDER_FINISH}
                previousStep={
                  bootstrapInfo?.application?.applicable_for_protection
                    ? StepsEnum.PAYMENT_PROTECTION
                    : StepsEnum.REPAYMENT_SCHEDULE_CONFIRM
                }
              />
            }
          />
          <Route path={StepsEnum.KYC_VERIFY} element={<KYCVerify />} />
          <Route path={StepsEnum.KYC_PERMISSIONS_ONFIDO_ERROR} element={<KYCOnfidoPermission />} />
          <Route path={StepsEnum.KYC_LOADING} element={<KYCDecisionLoader />} />
          <Route path={StepsEnum.KYC_RETRY_GENERIC} element={<KYCRetryGeneric />} />
          <Route path={StepsEnum.KYC_PII_RETRY} element={<PIIRetry />} />
          <Route path={StepsEnum.KYC_IMAGE_QUALITY_RETRY} element={<ImageQualityRetry />} />
          <Route
            path={StepsEnum.KYC_SUCCESS}
            element={<KYCSuccess nextStepOnSuccess={StepsEnum.PAYMENT_METHOD_SELECT} />}
          />
          <Route path={StepsEnum.KYC_FLINKS_FAIL} element={<KYCFlinksFail />} />
          <Route
            path={StepsEnum.KYC_FLINKS_INVALID_BANK_ACCOUNT}
            element={<KYCFlinksInvalidBankAccount />}
          />
          <Route path={StepsEnum.KYC_ID_FAIL} element={<KYCIdFail />} />
          <Route
            path={StepsEnum.OCCUPATION}
            element={
              <Occupation
                previousStep={
                  bootstrapInfo?.borrower?.borrower_mobile
                    ? StepsEnum.SELECT_OFFER
                    : skipOtp
                      ? StepsEnum.PHONE
                      : StepsEnum.SELECT_OFFER
                }
                nextStep={StepsEnum.LOAN_PURPOSE}
              />
            }
          />
          <Route
            path={StepsEnum.LOAN_PURPOSE}
            element={
              <PurposeOfLoan previousStep={StepsEnum.OCCUPATION} nextStep={StepsEnum.KYC_VERIFY} />
            }
          />
          <Route
            path={StepsEnum.REPAYMENT_SCHEDULE_CONFIRM}
            element={
              <ConfirmPaymentSchedule
                nextStep={
                  bootstrapInfo?.application?.applicable_for_protection
                    ? StepsEnum.PAYMENT_PROTECTION
                    : StepsEnum.ORDER_REVIEW
                }
                previousStep={StepsEnum.PAYMENT_METHOD_SELECT}
              />
            }
          />
          {bootstrapInfo?.application?.applicable_for_protection}?
          <Route
            path={StepsEnum.PAYMENT_PROTECTION}
            element={
              <PaymentProtection
                nextStep={StepsEnum.ORDER_REVIEW}
                previousStep={StepsEnum.REPAYMENT_SCHEDULE_CONFIRM}
              />
            }
          />
          : <Route path={StepsEnum.REPAYMENT_SCHEDULE} element={<EditPaymentSchedule />} />
          <Route path={StepsEnum.ORDER_DECLINED} element={<ApplicationOrderDeclined />} />
          <Route
            path={StepsEnum.ORDER_FINISH}
            element={<Finish bootstrapRefetch={bootstrapRefetch} />}
          />
          <Route
            path={StepsEnum.CREDIT_RENEWAL_ELIGIBILITY}
            element={<CreditRenewalEligibility />}
          />
          <Route
            path={StepsEnum.CREDIT_RENEWAL_CONFIRM_INFORMATION}
            element={<ConfirmInformation />}
          />
          <Route
            path={StepsEnum.CREDIT_RENEWAL_REVIEW}
            element={
              <Review
                nextStep={StepsEnum.CREDIT_RENEWAL_FINISH}
                previousStep={StepsEnum.CREDIT_RENEWAL_PAYMENT_PROTECTION}
              />
            }
          />
          <Route
            path={StepsEnum.CREDIT_RENEWAL_FINISH}
            element={<CreditRenewalFinish bootstrapRefetch={bootstrapRefetch} />}
          />
          <Route
            path={StepsEnum.CREDIT_RENEWAL_LOAN_DETAILS}
            element={<LoanDetails nextStep={StepsEnum.CREDIT_RENEWAL_PAYMENT_PROTECTION} />}
          />
          <Route
            path={StepsEnum.CREDIT_RENEWAL_REPAYMENT_SCHEDULE}
            element={
              <EditPaymentSchedule
                nextStep={StepsEnum.CREDIT_RENEWAL_LOAN_DETAILS}
                previousStep={StepsEnum.CREDIT_RENEWAL_LOAN_DETAILS}
              />
            }
          />
          <Route
            path={StepsEnum.CREDIT_RENEWAL_PAYMENT_PROTECTION}
            element={
              <CRPaymentProtection
                nextStep={StepsEnum.CREDIT_RENEWAL_REVIEW}
                previousStep={StepsEnum.CREDIT_RENEWAL_LOAN_DETAILS}
              />
            }
          />
          <Route path={StepsEnum.CREDIT_RENEWAL_DECLINED} element={<CreditRenewalDeclined />} />
          <Route path={StepsEnum.ERROR} element={<GenericError isUncaughtError={false} />} />
          <Route path={StepsEnum.DECLINED} element={<ApplicationDeclined />} />
          <Route path={StepsEnum.SETTLED} element={<ApplicationAlreadySettled />} />
          <Route path={StepsEnum.EXPIRED} element={<ApplicationExpired />} />
          <Route path={StepsEnum.BANK_CONNECT_ERROR} element={<BankConnectError />} />
          <Route path="*" element={<PageNotFound currentLocation={location.pathname} />} />
        </Route>
      )}
      <Route
        path="*"
        element={
          <OriginationProvider>
            <RootLayout logo={logo}>
              <ApplicationIdNotFound />
            </RootLayout>
          </OriginationProvider>
        }
      />
    </SentryRoutes>
  )
}

function AppRouterEndToEnd() {
  const {
    prequalResponse,
    jwtApiKey,
    partnerConfig,
    setJwtApiKey,
    setStepE2E,
    updatePartnerTrackingFields,
    setPartnerConfig,
    setE2EHomeStep,
    queryParams,
    setQueryParams,
    setPrequalGenericErrorPageError,
  } = useContext(PrequalificationContext)
  const { loanLimits } = useConfiguration()
  const location = useLocation()
  const { query } = parseUrl()

  const { getPartnerConfig } = useApiE2E()

  const { isLoading: partnerConfigLoading, status: partnerConfigStatus } = useQuery(
    ['getPartnerConfig', jwtApiKey],
    () => getPartnerConfig(),
    {
      enabled: jwtApiKey != null,
      useErrorBoundary: false,
      ...getRetryQueryOptionsWithErrorStep({
        queryName: 'getPartnerConfig',
        setGenericErrorPageError: setPrequalGenericErrorPageError,
        setStep: setStepE2E,
      }),
      onSuccess: data => {
        setPartnerConfig(data)
        setStepE2E(StepsEnum.LANDING)
        setE2EHomeStep(StepsEnum.LANDING)
        Sentry.setTag(SENTRY_TAGS.partnerId, data.partner_id)
      },
    },
  )

  const JWTWrapperE2E = () => {
    const { jwtApiKey: jwtApiKeyUrl } = useParams()
    const { isMaintenance } = useFlags<FeatureFlags>()

    useEffect(() => {
      if (jwtApiKeyUrl == null) {
        Sentry.captureMessage('JWT not found in url', 'error')
        setStepE2E(StepsEnum.PAGE_NOT_FOUND)
        return
      }
      const queryParams = new URLSearchParams(query)
      setQueryParams(query)
      updatePartnerTrackingFields({
        partnerSessionId: queryParams.get('partner_session_id'),
        campaignId: queryParams.get('campaign_id'),
        publisherId: queryParams.get('publisher_id'),
      })
      setJwtApiKey(jwtApiKeyUrl)
    }, [jwtApiKeyUrl])

    const isReady =
      partnerConfigStatus == 'error' ||
      (!partnerConfigLoading && !!partnerConfig && !loanLimits.isLoading)

    if (isMaintenance) {
      return (
        <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
          <MaintenanceMode />
        </RootLayout>
      )
    }

    if (isReady) {
      return <Outlet />
    }

    return <LoadingAnimation />
  }

  return (
    <SentryRoutes>
      <Route path=":jwtApiKey" element={<JWTWrapperE2E />}>
        <Route path={StepsEnum.LANDING} element={<Landing />} />
        <Route
          path={StepsEnum.CONSENTS}
          element={
            <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
              <Consents />
            </RootLayout>
          }
        />
        <Route index element={<Navigate to={StepsEnum.PREQUALIFICATION + queryParams} />} />
        <Route path={StepsEnum.PREQUALIFICATION} element={<Prequalification />} />
        {prequalResponse?.status === ApplicationStatus.PRE_AUTHORIZED && (
          <Route
            path={StepsEnum.OFFER}
            element={
              <RootLayout
                logo={{ src: logoImgSvg, alt: 'Fig logo' }}
                contentStyles={{ [theme.breakpoints.up('md')]: { width: 800 } }}
              >
                <Offer />
              </RootLayout>
            }
          />
        )}
        {prequalResponse?.status === ApplicationStatus.DECLINED && (
          <Route
            path={StepsEnum.DECLINED}
            element={
              <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
                <ApplicationDeclined />
              </RootLayout>
            }
          />
        )}
        {prequalResponse?.status === ApplicationStatus.DECLINED &&
          prequalResponse?.is_second_look_approved && (
            <Route
              path={StepsEnum.SECOND_LOOK_OFFER}
              element={
                <RootLayout
                  logo={{ src: logoImgSvg, alt: 'Fig logo' }}
                  contentStyles={{ [theme.breakpoints.up('md')]: { width: 800 } }}
                >
                  <SecondLookOffer />
                </RootLayout>
              }
            />
          )}
        <Route
          path={StepsEnum.ERROR}
          element={
            <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
              <GenericError isUncaughtError={false} />
            </RootLayout>
          }
        />
        <Route
          path={StepsEnum.PAGE_NOT_FOUND}
          element={
            <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
              <PageNotFound
                tryAgainScreen={StepsEnum.PREQUALIFICATION}
                setStepOverride={setStepE2E}
                currentLocation={location.pathname}
              />
            </RootLayout>
          }
        />
        <Route
          path="*"
          element={
            <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
              <PageNotFound currentLocation={location.pathname} />
            </RootLayout>
          }
        />
      </Route>
    </SentryRoutes>
  )
}

export default AppRouter
/**
 *
 */
export function AppRouter() {
  return (
    <SentryRoutes>
      <Route
        path={`/${StepsEnum.END_TO_END}/*`}
        element={
          <PrequalificationProvider>
            <AutoSaveProvider>
              <UnauthenticatedFreshChatWrapper shouldShow>
                <AppRouterEndToEnd />
              </UnauthenticatedFreshChatWrapper>
            </AutoSaveProvider>
          </PrequalificationProvider>
        }
      />
      <Route path={`/*`} element={<AppRouterOrigination />}></Route>
    </SentryRoutes>
  )
}
